export const TestRefs = () => [
  {
    fieldName: 'CREDHISTORY_RADIO',
    fieldValues: [
      {
        name: 'Нет, кредиты не брал',
        sort: 20,
        value: 'Нет, кредиты не брал'
      },
      {
        name: 'Да, есть погашенные кредиты',
        sort: 10,
        value: 'Да, есть погашенные кредиты'
      },
      {
        name: 'Да, есть непогашенные кредиты',
        sort: 0,
        value: 'Да, есть непогашенные кредиты'
      }
    ]
  },
  {
    fieldName: 'EDUCATION',
    fieldValues: [
      {
        name: 'Среднее/среднее специальное',
        sort: 0,
        value: 'Среднее/среднее специальное'
      },
      {
        name: 'Неполное высшее',
        sort: 10,
        value: 'Неполное высшее'
      },
      {
        name: 'Высшее',
        sort: 20,
        value: 'Высшее'
      },
      {
        name: 'Ученая степень',
        sort: 30,
        value: 'Ученая степень'
      }
    ]
  },
  {
    fieldName: 'EXPER_RADIO',
    fieldValues: [
      {
        name: 'От 3-х до 5-ти лет',
        sort: 30,
        value: 'От 3-х до 5-ти лет'
      },
      {
        name: 'От года до 3-х лет',
        sort: 20,
        value: 'От года до 3-х лет'
      },
      {
        name: 'От 3 мес. до года',
        sort: 10,
        value: 'От 3 мес. до года'
      },
      {
        name: 'До 3 мес.',
        sort: 0,
        value: 'До 3 мес.'
      },
      {
        name: 'От 5-ти до 10-ти лет',
        sort: 40,
        value: 'От 5-ти до 10-ти лет'
      },
      {
        name: 'Свыше 10-ти лет',
        sort: 50,
        value: 'Свыше 10-ти лет'
      }
    ]
  },
  {
    fieldName: 'FINANCIAL_POSITION',
    fieldValues: [
      {
        name: 'удовлетворительное',
        sort: 0,
        value: 'удовлетворительное'
      },
      {
        name: 'запущена процедура банкротства',
        sort: 10,
        value: 'запущена процедура банкротства'
      },
      {
        name: 'признан банкротом',
        sort: 10,
        value: 'признан банкротом'
      },
      {
        name: 'ранее был признан банкротом',
        sort: 20,
        value: 'ранее был признан банкротом'
      },
      {
        name: 'выступает ответчиком в судебном споре',
        sort: 10,
        value: 'выступает ответчиком в судебном споре'
      }
    ]
  },
  {
    fieldName: 'GENDER',
    fieldValues: [
      {
        name: 'Мужчина',
        sort: 0,
        value: 'M'
      },
      {
        name: 'Женщина',
        sort: 10,
        value: 'F'
      }
    ]
  },
  {
    fieldName: 'MARITAL_STATUS',
    fieldValues: [
      {
        name: 'Замужем/женат',
        sort: 0,
        value: 'Замужем/женат'
      },
      {
        name: 'Не замужем/холост',
        sort: 10,
        value: 'Не замужем/холост'
      },
      {
        name: 'Разведен/разведена',
        sort: 20,
        value: 'Разведен/разведена'
      },
      {
        name: 'Вдова/вдовец',
        sort: 30,
        value: 'Вдова/вдовец'
      }
    ]
  },
  {
    fieldName: 'MINOR_CHILDREN',
    fieldValues: [
      {
        name: 'Есть',
        sort: 0,
        value: 'Y'
      },
      {
        name: 'Нет',
        sort: 10,
        value: 'N'
      }
    ]
  },
  {
    fieldName: 'PURPOSE_OF_THE_LOAN',
    fieldValues: [
      {
        name: 'Ремонт квартиры\\автомобиля\\дачи и т.д',
        sort: 10,
        value: 'Ремонт квартиры\\автомобиля\\дачи и т.д'
      },
      {
        name: 'Оплата обучения/детского сада  Оплата лечения',
        sort: 10,
        value: 'Оплата обучения/детского сада  Оплата лечения'
      },
      {
        name: 'Покупка техники\\мебели',
        sort: 10,
        value: 'Покупка техники\\мебели'
      },
      {
        name: 'На неотложные нужды',
        sort: 10,
        value: 'На неотложные нужды'
      },
      {
        name: 'Семейные события (свадьба, похороны и т.д.)',
        sort: 10,
        value: 'Семейные события (свадьба, похороны и т.д.)'
      },
      {
        name: 'Прочее',
        sort: 0,
        value: 'Прочее'
      }
    ]
  },
  {
    fieldName: 'SOURCE_OF_INCOME',
    fieldValues: [
      {
        name: 'постоянная з/п ',
        sort: 0,
        value: 'постоянная з/п '
      },
      {
        name: 'пенсия',
        sort: 0,
        value: 'пенсия'
      },
      {
        name: 'соц.выплаты',
        sort: 0,
        value: 'соц.выплаты'
      },
      {
        name: 'сдача в аренду',
        sort: 0,
        value: 'сдача в аренду'
      }
    ]
  },
  {
    fieldName: 'TYPE_OF_ACTIVITY',
    fieldValues: [
      {
        name: 'Добывающая промышленность',
        sort: 0,
        value: 'Добывающая промышленность'
      },
      {
        name: 'Здравоохранение',
        sort: 0,
        value: 'Здравоохранение'
      },
      {
        name: 'Телекоммуникации, ИТ',
        sort: 0,
        value: 'Телекоммуникации, ИТ'
      },
      {
        name: 'Легкая и пищевая промышленность',
        sort: 0,
        value: 'Легкая и пищевая промышленность'
      },
      {
        name: 'Машиностроение и металлообработка',
        sort: 0,
        value: 'Машиностроение и металлообработка'
      },
      {
        name: 'Наука и культура',
        sort: 0,
        value: 'Наука и культура'
      },
      {
        name: 'Образование',
        sort: 0,
        value: 'Образование'
      },
      {
        name: 'Общественное питание (кафе/ресторан)',
        sort: 0,
        value: 'Общественное питание (кафе/ресторан)'
      },
      {
        name: 'Охранное предприятие',
        sort: 0,
        value: 'Охранное предприятие'
      },
      {
        name: 'Реклама, маркетинг, PR, СМИ',
        sort: 0,
        value: 'Реклама, маркетинг, PR, СМИ'
      },
      {
        name: 'Сельское хозяйство',
        sort: 0,
        value: 'Сельское хозяйство'
      },
      {
        name: 'Силовые структуры',
        sort: 0,
        value: 'Силовые структуры'
      },
      {
        name: 'Сфера услуг',
        sort: 0,
        value: 'Сфера услуг'
      },
      {
        name: 'Оптовая торговля',
        sort: 0,
        value: 'Оптовая торговля'
      },
      {
        name: 'Риелторская деятельность',
        sort: 0,
        value: 'Риелторская деятельность'
      },
      {
        name: 'Розничная торговля',
        sort: 0,
        value: 'Розничная торговля'
      },
      {
        name: 'Курьер',
        sort: 0,
        value: 'Курьер'
      },
      {
        name: 'Туризм',
        sort: 0,
        value: 'Туризм'
      },
      {
        name: 'Федеральное/муниципальное управление',
        sort: 0,
        value: 'Федеральное/муниципальное управление'
      },
      {
        name: 'Финансы, страховое/банковское дело',
        sort: 0,
        value: 'Финансы, страховое/банковское дело'
      },
      {
        name: 'Химия, парфюмерия, фармацевтика',
        sort: 0,
        value: 'Химия, парфюмерия, фармацевтика'
      },
      {
        name: 'Юридические и нотариальные услуги',
        sort: 0,
        value: 'Юридические и нотариальные услуги'
      },
      {
        name: 'Такси',
        sort: 0,
        value: 'Такси'
      },
      {
        name: 'Другое',
        sort: 0,
        value: 'Другое'
      }
    ]
  },
  {
    fieldName: 'TYPE_OF_EMPLOYMENT',
    fieldValues: [
      {
        name: 'Без официального трудоустройства',
        sort: 0,
        value: 'Без официального трудоустройства'
      },
      {
        name: 'Наемный сотрудник',
        sort: 0,
        value: 'Наемный сотрудник'
      },
      {
        name: 'Индивидуальный предприниматель',
        sort: 0,
        value: 'Индивидуальный предприниматель'
      },
      {
        name: 'Учредитель/участник ООО',
        sort: 0,
        value: 'Учредитель/участник ООО'
      },
      {
        name: 'Пенсионер',
        sort: 0,
        value: 'Пенсионер'
      },
      {
        name: 'Студент',
        sort: 0,
        value: 'Студент'
      },
      {
        name: 'Безработный',
        sort: 0,
        value: 'Безработный'
      },
      {
        name: 'Самозанятый',
        sort: 0,
        value: 'Самозанятый'
      },
      {
        name: 'Военнослужащий',
        sort: 0,
        value: 'Военнослужащий'
      }
    ]
  },
  {
    fieldName: 'TYPE_OF_ORGANIZATION',
    fieldValues: [
      {
        name: 'Государственная',
        sort: 0,
        value: 'Государственная'
      },
      {
        name: 'Частная',
        sort: 0,
        value: 'Частная'
      }
    ]
  },
  {
    fieldName: 'WHERE_T_FIND_US',
    fieldValues: [
      {
        name: 'Социальные сети',
        sort: 0,
        value: 'Социальные сети'
      },
      {
        name: 'Рекламная рассылка',
        sort: 0,
        value: 'Рекламная рассылка'
      },
      {
        name: 'Печатное объявление',
        sort: 0,
        value: 'Печатное объявление'
      },
      {
        name: 'Реклама на ТВ',
        sort: 0,
        value: 'Реклама на ТВ'
      },
      {
        name: 'Рекомендация друзей, знакомых',
        sort: 0,
        value: 'Рекомендация друзей, знакомых'
      },
      {
        name: 'Другое',
        sort: 0,
        value: 'Другое'
      }
    ]
  }
];

export const TestUserInfo = () => ({
  "REGISTRATION_ADDRESS_DADATA": {
    "value": "г Москва, ул Грина, д 5",
    "unrestricted_value": "г Москва, р-н Северное Бутово, ул Грина, д 5",
    "data": {
      "postal_code": null,
      "country": "Россия",
      "country_iso_code": "RU",
      "federal_district": "Центральный",
      "region_fias_id": "0c5b2444-70a0-4932-980c-b4dc0d3f02b5",
      "region_kladr_id": "7700000000000",
      "region_iso_code": "RU-MOW",
      "region_with_type": "г Москва",
      "region_type": "г",
      "region_type_full": "город",
      "region": "Москва",
      "area_fias_id": null,
      "area_kladr_id": null,
      "area_with_type": null,
      "area_type": null,
      "area_type_full": null,
      "area": null,
      "city_fias_id": "0c5b2444-70a0-4932-980c-b4dc0d3f02b5",
      "city_kladr_id": "7700000000000",
      "city_with_type": "г Москва",
      "city_type": "г",
      "city_type_full": "город",
      "city": "Москва",
      "city_area": "Юго-западный",
      "city_district_fias_id": null,
      "city_district_kladr_id": null,
      "city_district_with_type": null,
      "city_district_type": null,
      "city_district_type_full": null,
      "city_district": null,
      "settlement_fias_id": null,
      "settlement_kladr_id": null,
      "settlement_with_type": null,
      "settlement_type": null,
      "settlement_type_full": null,
      "settlement": null,
      "street_fias_id": "242912a9-7de3-4120-8bee-8dca086df20f",
      "street_kladr_id": "77000000000114000",
      "street_with_type": "ул Грина",
      "street_type": "ул",
      "street_type_full": "улица",
      "street": "Грина",
      "stead_fias_id": null,
      "stead_cadnum": null,
      "stead_type": null,
      "stead_type_full": null,
      "stead": null,
      "house_fias_id": null,
      "house_kladr_id": null,
      "house_cadnum": null,
      "house_type": "д",
      "house_type_full": "дом",
      "house": "5",
      "block_type": null,
      "block_type_full": null,
      "block": null,
      "entrance": null,
      "floor": null,
      "flat_fias_id": null,
      "flat_cadnum": null,
      "flat_type": null,
      "flat_type_full": null,
      "flat": null,
      "flat_area": null,
      "square_meter_price": null,
      "flat_price": null,
      "room_fias_id": null,
      "room_cadnum": null,
      "room_type": null,
      "room_type_full": null,
      "room": null,
      "postal_box": null,
      "fias_id": "242912a9-7de3-4120-8bee-8dca086df20f",
      "fias_code": null,
      "fias_level": "7",
      "fias_actuality_state": "0",
      "kladr_id": "77000000000114000",
      "geoname_id": "524901",
      "capital_marker": "0",
      "okato": "45293582000",
      "oktmo": "45906000",
      "tax_office": "7727",
      "tax_office_legal": "7727",
      "timezone": "UTC+3",
      "geo_lat": "55.565341",
      "geo_lon": "37.577481",
      "beltway_hit": "OUT_MKAD",
      "beltway_distance": "2",
      "metro": null,
      "divisions": null,
      "qc_geo": "2",
      "qc_complete": null,
      "qc_house": null,
      "history_values": null,
      "unparsed_parts": null,
      "source": null,
      "qc": null
    }
  },
  "RESIDENTIAL_ADDRESS_DADATA": {
    "value": "г Москва, ул Грина, д 5",
    "unrestricted_value": "г Москва, р-н Северное Бутово, ул Грина, д 5",
    "data": {
      "postal_code": null,
      "country": "Россия",
      "country_iso_code": "RU",
      "federal_district": "Центральный",
      "region_fias_id": "0c5b2444-70a0-4932-980c-b4dc0d3f02b5",
      "region_kladr_id": "7700000000000",
      "region_iso_code": "RU-MOW",
      "region_with_type": "г Москва",
      "region_type": "г",
      "region_type_full": "город",
      "region": "Москва",
      "area_fias_id": null,
      "area_kladr_id": null,
      "area_with_type": null,
      "area_type": null,
      "area_type_full": null,
      "area": null,
      "city_fias_id": "0c5b2444-70a0-4932-980c-b4dc0d3f02b5",
      "city_kladr_id": "7700000000000",
      "city_with_type": "г Москва",
      "city_type": "г",
      "city_type_full": "город",
      "city": "Москва",
      "city_area": "Юго-западный",
      "city_district_fias_id": null,
      "city_district_kladr_id": null,
      "city_district_with_type": null,
      "city_district_type": null,
      "city_district_type_full": null,
      "city_district": null,
      "settlement_fias_id": null,
      "settlement_kladr_id": null,
      "settlement_with_type": null,
      "settlement_type": null,
      "settlement_type_full": null,
      "settlement": null,
      "street_fias_id": "242912a9-7de3-4120-8bee-8dca086df20f",
      "street_kladr_id": "77000000000114000",
      "street_with_type": "ул Грина",
      "street_type": "ул",
      "street_type_full": "улица",
      "street": "Грина",
      "stead_fias_id": null,
      "stead_cadnum": null,
      "stead_type": null,
      "stead_type_full": null,
      "stead": null,
      "house_fias_id": null,
      "house_kladr_id": null,
      "house_cadnum": null,
      "house_type": "д",
      "house_type_full": "дом",
      "house": "5",
      "block_type": null,
      "block_type_full": null,
      "block": null,
      "entrance": null,
      "floor": null,
      "flat_fias_id": null,
      "flat_cadnum": null,
      "flat_type": null,
      "flat_type_full": null,
      "flat": null,
      "flat_area": null,
      "square_meter_price": null,
      "flat_price": null,
      "room_fias_id": null,
      "room_cadnum": null,
      "room_type": null,
      "room_type_full": null,
      "room": null,
      "postal_box": null,
      "fias_id": "242912a9-7de3-4120-8bee-8dca086df20f",
      "fias_code": null,
      "fias_level": "7",
      "fias_actuality_state": "0",
      "kladr_id": "77000000000114000",
      "geoname_id": "524901",
      "capital_marker": "0",
      "okato": "45293582000",
      "oktmo": "45906000",
      "tax_office": "7727",
      "tax_office_legal": "7727",
      "timezone": "UTC+3",
      "geo_lat": "55.565341",
      "geo_lon": "37.577481",
      "beltway_hit": "OUT_MKAD",
      "beltway_distance": "2",
      "metro": null,
      "divisions": null,
      "qc_geo": "2",
      "qc_complete": null,
      "qc_house": null,
      "history_values": null,
      "unparsed_parts": null,
      "source": null,
      "qc": null
    }
  },
  "AVERAGE_EXPENSES_PER_MONTH": "10000",
  "AVERAGE_MONTHLY_EXPENSES_FOR_LOAN_PAYMENTS": "10000",
  "BIRTHDAY": "01.01.1985",
  "BIRTHPLACE": "Москва",
  "CAR_NUMBER": "",
  "CONST_INCOME": "150000",
  "CREDHISTORY_RADIO": "",
  "EDUCATION": "Высшее",
  "MARITAL_STATUS": "Замужем/женат",
  "MINOR_CHILDREN": false,
  "EMAIL": "test@test.test",
  "EMAILreq": "",
  "EXPER_RADIO": "От 3-х до 5-ти лет",
  "FIRST_NAME": "ТЕСТ",
  "GENDER": "M",
  "IS_ADDRESS_COINCIDES": "Y",
  "IS_I_CERTIFY_REGISTRATION": "Y",
  "IS_I_CERTIFY_MARKETING": "Y",
  "IS_ASSIGNMENT_RIGHT": true,
  "LAST_NAME": "ТЕСТ",
  "MEETING_DATE": "",
  "MEETING_HOUR": "",
  "OTHER_SOURCES_OF_INCOME": "20000",
  "PASSPORT_DATE": "01.01.2003",
  "PASSPORT_DEP": "ПВО ОВД РАЙОНА СЕВЕРНОЕ БУТОВО Г. МОСКВЫ",
  "PASSPORT_DEP_CODE": "111-111",
  "PASSPORT_NUMBER": "123456",
  "PASSPORT_SERIES": "1234",
  "PHONE": "+7 (903) 207-38-48",
  "PRODUCT_ID": "S",
  "REGISTRATION_ADDRESS": "г Москва, ул Грина, д 5",

  "RESIDENTIAL_ADDRESS": "г Москва, ул Грина, д 5",

  "SECOND_NAME": "ТЕСТ",
  "WORK_JOB": "ООО \"РОГА И КОПЫТА\"",
  "WHERE_TO_FIND_US": "",
  "REFFER": "C сайта",
  "SOURCE_OF_INCOME": "постоянная з/п ",
  "PURPOSE_OF_THE_LOAN": "Прочее",
  "FINANCIAL_POSITION": "удовлетворительное",
  "ASP": true,
  "INN": "",
  "PAYMENT_TYPE": "",
  "IU_FACE_AGREEMENT": false,
  "SNILS": "925-275-848-37",
  "NOT_RELATED": true,
  "MY_OWN_BENEFIT": true,
  "ACTIONS_CONTROL": true,
  "THIRD_PARTIES": false,
  "ACCEPTANCE_OF_THE_TERMS": false,
  "AGREEMENT_WITH_THE_RULES": false,
  "AGREEMENT_LONGATSIA_RULES": false,
  "AGREEMENT_DATA_TRANSMISSION": false,
  "AGREEMENT_KI_REQUEST": false,
  "GENERAL_TERMS_OF_MICROLOAN": false,
  "RULES_FOR_MICROLOAN": false,
  "APPLICATION_MICROLOAN": false,
  "AGREEMENT_IU": false,
  "LOAN_WITH_INSURANCE": false,
  "REQURENT_PAY": false,
  "smartIdFileID": 0

})