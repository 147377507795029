/* eslint-disable */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  const notifyUserAboutUpdate = worker => {
    console.log('notifyUserAboutUpdate');
    console.log(worker);
    worker.postMessage({action:"skipWaiting"});
  };

  let test = register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (reqistration) {
      console.log('New content is available; please refresh.');
      notifyUserAboutUpdate(reqistration.waiting);
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  });
  try {
    var refreshing;
    console.log('refreshing ' + refreshing)
    navigator.serviceWorker.addEventListener('controllerchange',function(){
      console.log('controllerchange ' + refreshing)
      if (refreshing) return;
      window.location.reload();
      refreshing = true;
    });
  } catch (e) {
    console.log('controllerchange catch ' + refreshing)
    console.log(e)
  }
}
