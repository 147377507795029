import $myApi from '@/gavnoCode/ApiHandler';
import $external from '@/gavnoCode/external';
const state = {
  pskTable: [],
  selectSumm:0,
  selectProduct:'',
  selectWeek:0,
};

const actions = {
  addPskTable: ({commit, getters}, newPsk) => {
    //если таблички одинаковые, то не творим новый гавнокодик в промисах
    if (JSON.stringify(state.pskTable) !== JSON.stringify(newPsk)) {
      commit('updatePsk', newPsk);
      let baseProduct = getters.getProduct.filter((item)=>item.ProductType == 'S');
      if (baseProduct.length > 0) {
        commit('setSelectProduct', baseProduct[0]['ProductType'])
      }
      let defSumm = getters.getAvailableSum;
      if (defSumm.length > 0) {
        commit('setSelectSumm', defSumm[defSumm.length - 1]);
      }
      let defWeek = getters.getAvailablePeriod;
      if (defWeek.length > 0) {
        commit('setSelectWeek', defWeek[0]);
      }
    }
  },
  loadDefaultPsk:({dispatch})=>{
    $myApi.singleRequest({
      action:'loadDefaultPsk'
    }).then(res=> dispatch('addPskTable', res))
    .catch(res=>console.log(res));
  },
  loadPsk:({dispatch})=>{
    //проверяем изменения в пск, если придет пустой массив изменений нет
    // иначе сохроняем в мускл новые пск в клиент показываем принятый массив
    $external.universalRequest({
      action:'getPsk',
      externalType: 'zita'
    }).then(res=>{
      if(typeof res == "object" && res.length > 0) {
        dispatch('addPskTable', res);
        $myApi.singleRequest({
          action:'savePsk',
          data:res,
        }).then(res=>console.log(res))
        .catch((res)=>{
          if ( res.errorCode > 0 || res.errorCode !== undefined ) {
            alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
          }
        });
      } else {
        dispatch('loadDefaultPsk');
      }
    })
    .catch(res=>{
      if ( res.errorCode > 0 || res.errorCode !== undefined ) {
        alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
      }
      console.log(res);
      dispatch('loadDefaultPsk');
    });
  },
};

const mutations = {
  updatePsk: (state, newPsk) => {
    state.pskTable = newPsk;
  },
  setSelectProduct:(state, newValue)=>{
    state.selectProduct = newValue;
  },
  setSelectSumm:(state, newValue)=>{
    state.selectSumm = newValue;
  },
  setSelectWeek:(state, newValue)=>{
    state.selectWeek = newValue;
  }
};

const getters = {
  getAvailableSum: (state) => {
    let result = [];

    try {
      let out = state.pskTable.filter(item => {
        //получаем доступнные варианты сумм для выбранного продукта
        return item.ProductType === state.selectProduct
      }).sort(function (a, b) {
        return a.Amount - b.Amount;
      });
      out.forEach(value => {
        let findResult = result.find(valueF => {
          return valueF == value.Amount;
        });
        if (findResult === undefined) {
          result.push(value.Amount);
        }
      })
    } catch (e) {
      console.log(e);
    }
    return result;
  },
  getProduct: (state) => {
    let result = [];
    try {
      state.pskTable.forEach(value => {
        let findResult = result.find(valueF => {
          return valueF.ProductType == value.ProductType;
        });
        if (findResult === undefined) {
          result.push({ProductType: value.ProductType, DlProductTypeName: value.DlProductTypeName});
        }
      });
    } catch (e) {
      console.log(e);
    }
    return result;
  },
  getAvailablePeriod: (state) => {
    let result = [];
    try {
      // выводим простыню по данному продукту product_id
      let currentPsk = state.pskTable.filter(value => value.ProductType === state.selectProduct);
      // находим уникальные значение по периоду в полученной простыне
      currentPsk.forEach(value => {
        let findResult = result.find(valueF => {
          return valueF == value.Period
        });
        if (findResult === undefined) {
          result.push(value.Period);
        }
      });
    } catch (e) {
      console.log(e);
    }
    try {
      if (result.length > 0) {
        state.selectWeek = result[result.length - 1];
      }

    } catch (e) {
      console.log(e);
    }
    return result;

  },
  getSelectedPskInfo: (state) => {
    //ишем соовтевтсиве в табличке по сумме и типу продукта
    let filteredRow = state.pskTable.filter(item => {
      return item.Amount == state.selectSumm
        && item.ProductType == state.selectProduct
        && item.Period == state.selectWeek
    });
    if (filteredRow.length == 1) {
      //возращаем значение для данной суммы с расширением переплата продукта
      filteredRow[0]['OutDeltaPayments'] = Math.ceil((filteredRow[0]['OutPayments'] - state.selectSumm)*100)/100;
      //хардкор костыль на красивое окончание
      switch (filteredRow[0]['PeriodType_id']) {
        case '1':
          filteredRow[0]['calcWeekTitle'] = '(Недели)';
          filteredRow[0]['periodPayTitle'] = 'Еженедельный';
          break;
        case '2':
          filteredRow[0]['calcWeekTitle'] = '(Месяцы)';
          filteredRow[0]['periodPayTitle'] = 'Ежемесячный';
          break;
        default:
          filteredRow[0]['calcWeekTitle'] = '';
          filteredRow[0]['periodPayTitle'] = '';
          break;
      }
      return filteredRow[0];
    } else {
      return {
        Amount: 0,
        DlProductTypeName: '-',
        FixedPay: 0,
        OutPayments: 0,
        PercentageRate: 0,
        Period: 0,
        ProductType: 0,
        DlProductTypeID: 0,
        PeriodType: 0,
        PeriodType_id: 0,
        OutDeltaPayments: 0
      };
    }
  },
  getSelectedValue:(state)=>{
    return {
      CALC_SUMM:state.selectSumm,
      CALC_WEEK:state.selectWeek,
      PRODUCT_ID:state.selectProduct,
    }
  }
};

export default {
  state,
  mutations,
  getters,
  actions
}
