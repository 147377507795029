import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/auth';
import standalone from "@/store/standalone";
import dadata from '@/store/dadata';
import contentData from "@/store/contentData";
import modal from "@/store/modal";
import psk from '@/store/psk';
import Form from '@/store/Form/Form';
import repeatLoan from '@/store/repeatLoan/repeatLoan';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    requestCounter: 0,
    isLoading: false
  },
  getters:{
    RequestCount: state => state.requestCounter
  },
  actions: {
    incRequestCount: ({commit}) => {
      commit('incRequestCount');
    },
    resRequestCount: ({commit}) => {
      commit('setRequestCounter', 0);
    },
    setIsLoading({commit}, value) {
      commit('setIsLoading', value);
    }
  },
  mutations: {
    incRequestCount: (state, value) => state.requestCounter += 1,
    setIsLoading: (state, value) => state.isLoading = value
  },
  modules:{
    auth,
    standalone,
    dadata,
    contentData,
    modal,
    psk,
    Form,
    repeatLoan
  }
})
