import myApi from '@/gavnoCode/ApiHandler';
import { getDealsInfo } from '@/api/contentDataApi';

const getDefaultState = () => {
  return {
    deals: {
      lastTimeUpload: null,
      isLoading: null,
      data: [],
      activeDemand: 0
    },
    aggInfo: []
  }
};
const isValidDate = (date) => {
  return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
};
const needUpdate = (timeValue) => {
  //если нет корректной даты или время последнего обноления больше 12 часов (43200000ms), то сообщаем, что нужно
  // обновиться true
  if (isValidDate(timeValue)) {
    let currentTime = new Date();
    return currentTime.getTime() - timeValue.getTime() > 43200000;
  } else {
    return true;
  }
};
const demandStatusToTxt = (status) => {
  let out = '';
  switch (status) {
    case 0:
      out = 'Договор активный';
      break;
    case 1:
      out = 'Договор успешно закрыт';
      break;
    case 2:
      out = 'Договор на стадии оформления';
      break;
    default:
      out = 'Нет статуса';
  }
  return out;
};
const state = getDefaultState();
const getters = {
  activeDealObj: (state) => {
    let i = 0;
    let searchDeal = true;
    let out = {};
    const DealsArray = state.deals.data;
    if (DealsArray.length === 0) {
      return {};
    }
    if (state.deals.activeDemand === 0) {
      return {};
    }

    while (searchDeal) {
      const { Demand } = DealsArray[i];
      if (Demand === state.deals.activeDemand) {
        searchDeal = false;
        out = DealsArray[i];
      } else {
        if (DealsArray[i + 1] !== undefined) {
          i += 1;
        } else {
          searchDeal = false;
        }
      }
    }

    return out;
  },
  activeDealData: (state, getters) => (objName) => {
    let avalaibleObj = ['Contact', 'DealInfo', 'PayGraphic', 'PayList', 'Demand'];
    if (!avalaibleObj.includes(objName)) {
      alert('неизвестный объект передан в vuex getters activeDealData');
      return
    }
    return getters.activeDealObj[objName];
  },
  paySum: (state, getters, rootState) =>
    Math.ceil(rootState.Form.paymentData.paymentBy === 'current'
      ? getters.activeDealData('DealInfo').CurPay
      : getters.activeDealData('DealInfo').SumForLong),
  demandsList: (state) => {
    let out = [];
    const DealArray = state.deals.data;
    for (let i = 0; i < DealArray.length; i++) {
      const { Demand, StateDeal } = DealArray[i];
      out.push({
        demand: Demand,
        demandStatusTxt: demandStatusToTxt(StateDeal),
        demandStatusInt: StateDeal
      });
    }
    return out;
  }
};

const actions = {
  returnPdlStatus: ({ state }, demand) => {
    return new Promise((resolve, reject) => {
      myApi.singleRequest({
        action: 'returnPdlStatus',
        type: 'pdl',
        demand: parseInt(demand, 10)
      })
        .then(res => { resolve(res) })
        .catch(res => { reject(res) })
    });
  },
  updateContentData: async ({ state, commit }, clearTime = false) => {
    if (clearTime) {
      commit('clearTimer');
    }
    if (!needUpdate(state.deals.lastTimeUpload) || state.deals.isLoading === true) {
      return;
    }
    commit('toggleComponentLoadingStatus', 'deals');
    try {
      const userId = localStorage.getItem('UserID');
      const sessionKey = localStorage.getItem('SessionKey');
      const { result, errorMsg } = await getDealsInfo(userId, sessionKey);
      if (!result) {
        throw new Error({ errorMsg });
      }
      if (Array.isArray(result.AggInfo)) {
        commit('setAggInfo', result.AggInfo);
      } else {
        throw new Error('AggInfo is not array');
      }
      if (Array.isArray(result.DealsInfo)) {
        commit('setComponentContent', {
          name: 'deals',
          data: result.DealsInfo
        });
        const demandId = state.deals.data.find(x => x.StateDeal === 0)?.Demand ?? Math.max(...state.deals.data.map(x => x.Demand));
        commit('setActiveDemand', demandId);
      } else {
        throw new Error('DealsInfo is not array');
      }
    } catch (e) {
      throw new Error('Ошибка портала, getDealsInfo ' + e?.message ?? JSON.stringify(e));
    }
  }
}

const mutations = {
  setActiveDemand: (state, newDemand) => {
    state.deals.activeDemand = newDemand
  },
  setAggInfo: (state, value) => state.aggInfo = value,
  clearTimer: (state) => state.deals.lastTimeUpload = null,
  resetState: (state) => Object.assign(state, getDefaultState()),
  toggleComponentLoadingStatus: (state, componentName) => {
    state[componentName].isLoading = !state[componentName].isLoading
  },
  setComponentContent: (state, obj) => {
    state[obj.name].lastTimeUpload = new Date();
    state[obj.name].data = obj.data;
    state[obj.name].error = '';
    state[obj.name].isLoading = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
}
