export default {
  scrollToTop(scrollDuration) {
    var cosParameter = window.scrollY / 2,
      scrollCount = 0,
      oldTimestamp = performance.now();

    function step(newTimestamp) {
      scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
      if (scrollCount >= Math.PI) window.scrollTo(0, 0);
      if (window.scrollY === 0) return;
      window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
      oldTimestamp = newTimestamp;
      window.requestAnimationFrame(step);
    }

    window.requestAnimationFrame(step);
  },
  checkPassword(password) {
    let lower = /(?=.*[a-zа-яё])/; // Буквы в нижнем регистре
    let upper = /(?=.*[A-ZА-ЯЁ])/; // Буквы в верхнем регистре
    let digits = /(?=.*[0-9])/; // Цифры

    let out = '';
    out += (password.length >= 6)?'':'<p>Минимальная длинна пароля 6 символов.</p>';
    out += (lower.test(password))?'':'<p>В пароле должен быть хотя бы один символ в нижнем регистре.</p>';
    out += (upper.test(password))?'':'<p>В пароле должен быть хотя бы один символ в верхнем регистре.</p>';
    out += (this.isNumber(password))?'':'<p>В пароле должено быть хотя бы одно число.</p>';

    return out;
  },
  isNumber(value){
    let digits = /(?=.*[0-9])/;
    return digits.test(value);
  },
  correctPhone(value){
    return value.replace(/[^0-9]/g, '').length === 11;
  },
  correctDlCode(value){
    return parseInt(value, 10) > 500000;
  },
}
