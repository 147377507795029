import myApi from '@/gavnoCode/ApiHandler';

export default {
  trackInputFocus: function (key,session_id) {
    myApi.singleRequest({
      action:'trackInputFocus',
      type:'palantirLnd',
      session_id:session_id,
      data:{
        key:key,
      }
    }).then((res)=>{
      if (res != undefined){
        res['asd'] = 'asd';
      }else {
        res = {asd:'asd'};
      }
    }).catch((res)=>{
      if (res != undefined){
        res['asd'] = 'asd';
      }else {
        res = {asd:'asd'};
      }
    })
  },
  trackInputBlur: function (key,value,session_id) {
      myApi.singleRequest({
        action:'trackInputBlur',
        type:'palantirLnd',
        session_id:session_id,
        data:{
          key:key,
          value:value
        }
      }).then((res)=>{
        if (res != undefined){
          res['asd'] = 'asd';
        }else {
          res = {asd:'asd'};
        }
      }).catch((res)=>{
        if (res != undefined){
          res['asd'] = 'asd';
        }else {
          res = {asd:'asd'};
        }
      })
  },
  trackWebAction:function (key,value,session_id) {
    myApi.singleRequest({
      action:'trackWebAction',
      type:'palantirLnd',
      session_id:session_id,
      data:{
        key:key,
        value:value
      }
    }).then((res)=>{
      if (res != undefined){
        res['asd'] = 'asd';
      }else {
        res = {asd:'asd'};
      }
    }).catch((res)=>{
      if (res != undefined){
        res['asd'] = 'asd';
      }else {
        res = {asd:'asd'};
      }
    })
  }
}
