import axios from 'axios';

const msUrl = process.env.VUE_APP_URL_API_MS;

export const loanDraftCreateSession = async function (UserID) {
  const { data } = await axios.post(`${msUrl}/loanDraftCreateSession.php`, { UserID });
  return data;
}

export const loanDraftTraceSession = async function (traceList, session_id, token) {
  const { data } =  await axios.post(`${msUrl}/loanDraftTraceSession.php`, { traceList, session_id, token });
  return data;
}