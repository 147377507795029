<template>
  <div id="app">
      <headerSection :selectedSection="selectedSection"></headerSection>
      <banner v-if='isAuthenticated'></banner>
      <loader v-if="isLoading"></loader>
      <router-view v-else></router-view>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'app',
  data() {
    return {
      version: 'xz',
      isMobileMenuOpen: false,
      engineeringWorks: false,
      selectedSection: 1
    }
  },
  components: {
    headerSection: () => import('@/components/nav/headerSection'),
    loader: () => import('@/components/other/loader'),
    banner: () => import('@/components/banner/bannerToMobLk.vue')
  },
  methods: {
    clearCache() {
      document.location.reload();
    },
    updateThisValue() {
      this.version = (localStorage.getItem('cache') === null) ? 'xz' : localStorage.getItem('cache');
    },

  },

  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapState({
      isLoading: state => state.isLoading,
    })
  },
  watch: {}

}
</script>

<style>

/*новые шрифты*/

@font-face {
  font-family: 'Lato';
  src: url('../public/font/lato/Lato-Regular.eot');
  src: local('Lato Regular'), local('Lato-Regular'),
  url('../public/font/lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
  url('../public/font/lato/Lato-Regular.woff2') format('woff2'),
  url('../public/font/lato/Lato-Regular.woff') format('woff'),
  url('../public/font/lato/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('../public/font/lato/Lato-Bold.eot');
  src: local('Lato Bold'), local('Lato-Bold'),
  url('../public/font/lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
  url('../public/font/lato/Lato-Bold.woff2') format('woff2'),
  url('../public/font/lato/Lato-Bold.woff') format('woff'),
  url('../public/font/lato/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Ubuntu';
  src: url('../public/font/ubuntu/Ubuntu-Bold.eot');
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
  url('../public/font/ubuntu/Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
  url('../public/font/ubuntu/Ubuntu-Bold.woff2') format('woff2'),
  url('../public/font/ubuntu/Ubuntu-Bold.woff') format('woff'),
  url('../public/font/ubuntu/Ubuntu-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Ubuntu';
  src: url('../public/font/ubuntu/Ubuntu-Regular.eot');
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
  url('../public/font/ubuntu/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
  url('../public/font/ubuntu/Ubuntu-Regular.woff2') format('woff2'),
  url('../public/font/ubuntu/Ubuntu-Regular.woff') format('woff'),
  url('../public/font/ubuntu/Ubuntu-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'),
  url('../public/font/roboto/Roboto.woff2') format('woff2'),
  url('../public/font/roboto/Roboto.woff') format('woff'),
  url('../public/font/roboto/Roboto.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
/*@font-face {*/
/*  font-family: 'Roboto';*/
/*  src: local('Roboto Bold'), local('Roboto-Bold'),*/
/*  url('../../public/font/roboto/Robotobold.woff2') format('woff2'),*/
/*  url('../../public/font/roboto/Robotobold.woff') format('woff'),*/
/*  url('../../public/font/roboto/Robotobold.ttf') format('truetype');*/
/*  font-weight: 700;*/
/*  font-style: normal;*/
/*}*/


/*старые шрифты*/


  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'), local('Roboto-Regular'), url('/font/roboto/Roboto.woff2') format('woff2'), url('/font/roboto/Roboto.woff') format('woff'), url('/font/roboto/Roboto.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto Medium'), local('Roboto-Medium'), url('/font/roboto/Robotomedium.woff2') format('woff2'), url('/font/roboto/Robotomedium.woff') format('woff'), url('/font/roboto/Robotomedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold'), local('Roboto-Bold'), url('/font/roboto/Robotobold.woff2') format('woff2'), url('/font/roboto/Robotobold.woff') format('woff'), url('/font/roboto/Robotobold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'rouble';
    src: url('/font/webfont-rouble/rouble-webfont.eot');
    src: url('/font/webfont-rouble/rouble-webfont.eot?#iefix') format('embedded-opentype'),
    url('/font/webfont-rouble/rouble-webfont.woff') format('woff'),
    url('/font/webfont-rouble/rouble-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
/*default font-size 16px;line-hieght:22px */
  html, body {font-family: 'Roboto'; margin: 0;padding: 0;font-size: 4.5vw;line-height:
    6.1vw;letter-spacing: 0.02em;}
  p, input, textarea, select, ul, li {margin: 0;padding: 0;}
  ul, li{list-style: none}
  p{margin-bottom: 5vw;}
  svg{display: inline-block;width: 100%;height: auto;outline: none;}

  /*font-size: 18px; line-height:28px;*/
  h4 {font-weight: 500;font-size: 5vw;line-height: 7.8vw;letter-spacing: 0.02em;margin: 0;padding: 0}
  /*font-size: 14px; line-height:20px;*/
  .stxt{font-size: 3.9vw;color:#828282;line-height: 5.6vw;letter-spacing: 0.02em;}

  .ALS {font-family: 'rouble'; font-weight: normal;font-style: normal;margin: 0 0.5vw;}

  .pixel{margin: 0;padding: 0;width: 0px;height: 0px;}

  #app.active{padding-bottom: 40vw;}
  .logo0{fill:#00563F;}
  .logo1{fill:#39B54A;}
  .logo2{fill:#004C26;}
  .logo3{fill:none;}
  .logo4{fill:#3FAE2A;}

  .layoutBorder
  {width: 94.5vw;margin: 4vw auto;background: #FFFFFF;box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);border-radius: 8px;}
  .layoutHeader {border-bottom: 2px solid #DFE0E1;padding: 5vw 5vw 4.5vw;}
  .layoutFooter {min-height: 6.6vw;border-top: 2px solid #DFE0E1;padding: 5vw 5vw 4.5vw;position: relative;}
  .layoutFooter.btnIsset{padding: 5vw 5vw 10vw;}
  .layoutBody {background: #F8FAFB;padding: 5vw 5vw 4.5vw;}

  button, likeButton {background: #43AC34;border: 1px solid rgba(28, 28, 28, 0.15);box-sizing: border-box;box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 8px;font-family: Roboto;font-style: normal;font-weight: bold;font-size: 4vw;line-height: 6.7vw;text-align: center;letter-spacing: 0.1em;text-transform: uppercase;color: #FFFFFF;padding: 4.4vw 5.8vw; cursor: pointer}
  .submitButton {position: absolute;bottom: -7vw;right: 5vw;}

  .inputBox{position: relative;margin: 2vw 0;}
  #autosuggest.inputBox{margin: 0;}
  .inputBox > input{background: #FFFFFF;border: 2px solid #DFE0E1;box-sizing: border-box;border-radius: 7px;outline:
    0;padding: 5vw 5vw 2vw;font-family: Roboto;font-style: normal;font-weight: normal;font-size: 5vw;line-height:
    6.6vw;letter-spacing: 0.1em;width: 100%;}
  .inputBox > input:focus{border: 2px solid #43AC34;}
  .errMsg{color:red;position: relative;}
  .inputBox > .errMsg{font-size: 3vw;padding: 1vw 5vw;}
  .inputBox > .errMsg:before{content: '';position: absolute;top:-3px;left:5vw;height: 4px;background: #FF303C;width: 25vw;}
  .inputBox > .errMsg p{margin: 0;}
  .inputBox > label {position: absolute;left: 5vw;top: 1vw;color: rgba(51, 51, 51, 0.54);pointer-events: none;transition: all .2s ease;pointer-events: none;font-size: 3vw;}
  .linkColor{color:#1990FE;border-bottom: 1px dashed #DFE0E1;text-decoration: none;}

  .descriptionData{position: relative;padding: 2vw 0;}
  .descriptionData:after{content:'';position: absolute;bottom: 0;width: 60%;left:calc(50% - 30%);height: 2px;background-color: #DFE0E1;}
  .descriptionData p{margin: 0;}
  .descriptionData p:nth-child(2){font-weight: bold;}

  .phoneLink{display: flex;align-items: center;font-size: 5.6vw;font-weight: bold;line-height: 8.4vw;}
  .phoneLink svg{width: 8vw;margin-right: 4vw;}

  #navLoginWrap{}
  #navLoginWrap a{display:inline-block;margin-bottom: 5vw;}

  .subBox{background: white;padding: 2vw;border-radius: 5px;box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);}
  .flex{display: flex;align-items: center;justify-content: space-between}

  @media screen and (min-width: 760px){
    html, body {font-size: 2.1vw;line-height: 2.1vw;}
    p{margin-bottom: 2vw;}

    /*font-size: 18px; line-height:28px;*/
    h4 {font-size: 2.4vw;line-height: 3.7vw;}
    /*font-size: 14px; line-height:20px;*/
    .stxt{font-size: 1.8vw;line-height:2.6vw;}

    .ALS {margin: 0;}
    #app{}
    #app.active{padding:2vw 2vw 15vw;}

    .layoutBorder {width: auto;margin: 2vw auto;}
    .layoutHeader {padding: 2.4vw 2.4vw 2.2vw;}
    .layoutFooter {min-height: 1vw;padding:  2.4vw 2.4vw 2.5vw;}
    .layoutFooter.btnIsset{padding: 2.4vw 2.4vw 5vw;}
    .layoutBody {padding: 2.4vw 2.4vw 4.5vw;}

    button {font-size: 1.4vw;line-height: 3.2vw;padding:1.9vw 2.7vw}
    .submitButton {bottom: -3vw;right: 2.4vw;}

    .inputBox{margin: 1vw 0;}
    .inputBox > input{padding: 2.4vw 2.4vw 0.5vw;font-size: 2.4vw;line-height: 3.2vw;}
    .inputBox > input:focus{}

    .inputBox > .errMsg{font-size: 1.4vw;padding: 0.5vw 2.4vw;}
    .inputBox > .errMsg:before{left:2.4vw;width:10vw;}
    .inputBox > label {left: 2.4vw;top: 0vw;font-size: 1.4vw;}
    .linkColor{}

    .descriptionData{padding: 1vw 0;}
    .descriptionData:after{width: 60%;left:calc(50% - 30%);height: 2px;}
    .descriptionData p{}
    .descriptionData p:nth-child(2){}

    .phoneLink{font-size: 2.6vw;line-height: 3.9vw;padding: 1vw 0;}
    .phoneLink svg{width: 4vw;margin-right: 1vw;}
    #navLoginWrap{}
    #navLoginWrap a{display:inline-block;margin-bottom: 5vw;}
    .subBox{padding: 1vw}
  }
  @media screen and (min-width: 1200px){
    html, body {font-size: 16px;line-height: 22px;letter-spacing: 0.02em;}
    p{margin-bottom: 5px;}

    /*font-size: 18px; line-height:28px;*/
    h4 {font-size: 18px;line-height: 28px;}
    /*font-size: 14px; line-height:20px;*/
    .stxt{font-size: 14px;line-height: 20px;letter-spacing: 0.02em;}

    .ALS {margin: 0 0px;}

    #app.active{padding-bottom: 0;padding-top: 50px;}

    .layoutBorder {margin: 10px auto;max-width: 1100px;}
    .layoutHeader {padding: 15px 20px;}
    .layoutFooter {min-height: 70px;padding: 15px 20px;}
    .layoutFooter.btnIsset{padding: 15px 20px;}
    .layoutBody {padding: 15px 20px;}

    button {font-size: 14px;line-height: 24px;padding: 15px 20px;}
    .submitButton {bottom: -30px;right: 20px;}

    .inputBox{margin: 6px 0;}
    #autosuggest.inputBox{margin: 0;}
    .inputBox > input{padding: 18px 18px 7px;font-size:18px;line-height: 23px;}
    .inputBox > input:focus{}
    .errMsg{font-size: 18px;}
    .inputBox > .errMsg{font-size: 10px;line-height:10px;padding: 3px 18px;}
    .inputBox > .errMsg:before{left:18px;width: 50px;}
    .inputBox > label {left: 18px;top: 0;font-size: 10px;}
    .linkColor{}

    .descriptionData{padding: 7px 0;}
    .descriptionData:after{width: 60%;left:0;height: 2px;}
    .descriptionData p{}
    .descriptionData p:nth-child(2){}

    .phoneLink{font-size: 20px;line-height: 20px;}
    .phoneLink svg{width: 25px;margin-right: 10px;}

    #navLoginWrap{}
    #navLoginWrap a{display:inline-block;margin-bottom: 15px;}
    .subBox{padding: 15px 20px}
  }

  @media print {
    html,body{font-size: 15px;line-height: 17px;}
    p{margin: 0 0 2px}
    table{margin: 10px 0;}
    #app.active{padding: 0;}
  }
</style>

<!--новые стили-->

<style>

.align { width: 87.35vw; margin: 0 auto }
.mobileOnly {}
.pcOnly { display: none}

* { line-height: normal; letter-spacing: unset }


#loader { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(227, 227, 227, 0.46)}

.contentBlock { box-shadow: 0 1.18vw 8.82vw 0 rgba(0, 0, 0, 0.10); border-radius: 8px; padding: 3.82vw 4.12vw 0; margin-bottom: 5.88vw}
.contentBlock a { color: #43AC34; text-decoration-line: underline; }
.contentBlock .title { border-bottom: 1px solid #DFE0E1; padding-bottom: 3.24vw; margin-bottom: 4.41vw }
.contentBlock h3 { font-family: 'Lato'; margin: 0; font-size: 5vw; line-height: 28px }
.contentBlock>.body .point { font-family: 'Lato' }
.contentBlock .point { width: 33vw; font-size: 3.24vw }
.contentBlock>.body .point>p { margin-bottom: 2.8vw }
.contentBlock>.body .point>p:first-child { color: #00563F }
.contentBlock>.body .point>p:last-child { color: #000 }
.contentBlock button { font-family: 'Ubuntu'; font-weight: bold; font-size: 3.24vw; box-shadow: none; line-height: normal }
@media screen and (min-width: 760px){
  .align { width: 100% }
  .mobileOnly {display: none}
  .pcOnly {display: block }
}
@media screen and (min-width: 1200px){
  .align { width: 1085px }
.contentBlock a {  }
.contentBlock { padding: 15px 21px 0; margin-bottom: 17px; box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.10) }
.contentBlock .title { padding-bottom: 16px; margin-bottom: 15px }
.contentBlock h3 { font-size: 17px }
.contentBlock .point { width: 169px; font-size: 17px }
.contentBlock>.body .point { margin-bottom: 9px }
.contentBlock>.body .point>p { line-height: 28px; font-size: 17px; margin-bottom: 0 }
.contentBlock button { font-size: 15px }
}
</style>

<style>
/*стили для полей*/
.inputBoxWrap ::placeholder { color:    #959595; }
.inputBoxWrap { font-family: 'Ubuntu'; position: relative; font-weight: 400; }
.inputBoxWrap .errFieldBorder { border-color:#ff00003d; }
.inputBoxWrap label, 
	.inputBoxWrap .errorMsg { letter-spacing: 0; position: absolute; font-size: 2.87vw; border-top-left-radius: 10px; pointer-events: none; background: #FFFFFF; left: 3.3vw; }
.inputBoxWrap .errorMsg { color: red; padding: 0 1.60vw; top: 10.5vw; }
.inputBoxWrap label { padding: 0 2.67vw; top: 1px; color: #869A95; transition: 0.3s ease-out; }
.inputBoxWrap input, 
	.inputBoxWrap select{ padding: 4vw 5.88vw; margin-bottom: 4.71vw; font-size: 3.47vw; border: 1px solid rgba(67, 172, 52, 0.5); border-radius: 10px ; width: calc(100% - 11.76vw); color: #959595; }
.inputBoxWrap select { display: block; padding: 3.83vw 4.6vw; width: calc(100% + 1vw); }
.inputBoxWrap select:focus ~ label { top: -2vw; }
.inputBoxWrap input:focus ~ label { top: -2vw }

@media (min-width: 760px) { 
	.inputBoxWrap label, .inputBoxWrap .errorMsg { font-size: 1.3vw; left: 1.7vw; padding: 0 1.5vw;}
	.inputBoxWrap .errorMsg { padding: 0 1vw; top: 5.1vw; }
  .inputBoxWrap input, 
		.inputBoxWrap select { padding: 0.89vw 3.2vw; border-radius: 10px; font-size: 1.8vw; margin-bottom: 1.29vw; color: #959595; width: calc(100% - 7vw); line-height: 4vw;}
  .inputBoxWrap	select {padding: 1.6vw 2.6vw; width: calc(100% - 0.4vw);}
  .inputBoxWrap select:focus ~ label { top:-0.5vw }
  .inputBoxWrap input:focus ~ label { top: -0.5vw }
}

@media (min-width: 1240px) {
	.inputBoxWrap label, .inputBoxWrap .errorMsg { left: 23px; }
  .inputBoxWrap label { font-size: 10px;  padding: 0 10px; }
  .inputBoxWrap .errorMsg { font-size: 10px; top: 36px; padding: 0 5px }
  .inputBoxWrap input, .inputBoxWrap select { padding: 12px 33px; font-size: 14px; margin-bottom: 16px ;width: calc(100% - 40px); line-height: normal; }
	.inputBoxWrap select {padding: 10px 28px; width: calc(100% + 28px);}
  .inputBoxWrap select:focus ~ label {top:-6px}
  .inputBoxWrap input:focus ~ label { top: -3px }
}


.bug_boxes{display: flex;flex-wrap: wrap}
.bug_box{width: 47.8%; padding: 10px; border: 1px solid black }
.bug_label{display: flex;align-items: center}
.bug_label input{margin-right: 10px;}
</style>