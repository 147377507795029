const getDefaultState = () => {
  return {
    content:'',
    show:false,
    closeBlocker:true
  }
};

const state =  getDefaultState();

const getters = {
  getContent: (state) => state.content,
  getShowStatus: (state) => state.show,
  getBlockStatus: (state) => state.closeBlocker,
};

const actions = {
  updateContent:({commit}, value) => {
    console.log('modal')
    return new Promise(resolve => {
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }
      commit('setContent', value);
      resolve();
    });
  },
  showModal:({commit, dispatch}, arrValue) => {
    return new Promise (resolve => {
      dispatch('updateContent', arrValue.content);
    });
  },
  switchShowStatus:({commit}, value) => {
    return new Promise(resolve => {
      // if (value)
    });
  },
  resetToDefault:({commit}) => {
    return new Promise(resolve => {
      commit('resetState');
      resolve();
    });
  },
};

const mutations = {
  resetState: (state) => Object.assign(state, getDefaultState()),
  setShowStatus: (state, value) => state.show = value,
  setContent: (state, value) => state.content = value,
  setBlockStatus: (state, value) => state.closeBlocker = value,
};

export default {
  state,
  getters,
  actions,
  mutations,
}

