import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import myApi from './gavnoCode/ApiHandler';
import bikeFunction from '@/gavnoCode/otherBikeAndFun';
import VueMask from 'v-mask';
import Vuetify from 'vuetify';
import palantirLnd from "@/gavnoCode/palantirLnd";

import kostili from "@/gavnoCode/Kostili";
import external from "@/gavnoCode/external";


Vue.config.productionTip = false;
Vue.prototype.$myApi = myApi;
Vue.prototype.$palantirLnd = palantirLnd;
Vue.prototype.$bikeFunction = bikeFunction;
Vue.prototype.$external = external;
Vue.prototype.$kostili = kostili;
Vue.use(VueMask);
Vue.use(Vuetify);


import myFilter from './gavnoCode/filters';
for(let name in myFilter) {
  Vue.filter(name, myFilter[name]);
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
