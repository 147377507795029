import { loanDraftCreateSession, loanDraftTraceSession } from '@/api/tracingApi';

let stack = new Map();
let lastKey = '';
export default {
  /**
   * Кладем шаг трассировки в стек
   * @param {number} action
   * @param {string} refs 
   * @param {(string|Object)} value
   */
  traceInput: ({ action, refs, value }) => {
    if (parseInt(action, 10) > 0) {
      lastKey = JSON.stringify({action, refs})
      const obj = { action, refs, value };
      const prevObj = stack.get(lastKey);

      obj.ts_start = prevObj ? prevObj.ts_start : Date.now();
      obj.ts_end = prevObj ? Date.now() : obj.ts_start;

      stack.set(lastKey, obj)
    }
  },

  startSession: async (UserID) => {
    try {
      const data = await loanDraftCreateSession(UserID);
      if(!data || data.errorCode) {
        throw new Error(data.errorMsg);
      }
      return data.result;
    } catch (e) {
      console.log(e);
    }
  },

  /**
   * получаем последнюю добавленную запись
   */
  getLastTrace: () => stack.get(lastKey),

  /**
   * Отправляем массив шагов на сервер
   */
  sendTrace: async (sessionId, token) => {
    if(!sessionId || !token) {
      console.log('Нет sessionId или token');
      return;
    }
    if (stack.size > 0) {
      const sendingTracing = new Map(stack);
      stack.clear();
      try {
        const data = await loanDraftTraceSession(Array.from(sendingTracing.values()), sessionId, token);
        if(!data || data.errorCode) {
          throw new Error(data?.errorMsg || 'Нет ответа');
        }
      } catch (e) {
        console.log(e);
        // потеряется информация о времени начала, альтернатива - ручная функция сравнения
        stack = new Map([...sendingTracing].concat([...stack]));
      }
    }
  },
};
