import {isAndroid, isIOS, isMobileSafari, isChrome} from 'mobile-device-detect';

const isIosUA = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};
const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

const state = {
  DeviceReadyFor: '',
};

const getters = {
  DeviceReadyFor: state => state.DeviceReadyFor,
};

const actions = {
  chekDeviceForInstallPWA: ({commit}) => {
    if (isIOS && isMobileSafari) {
      if (isIosUA() && !isInStandaloneMode()) {
        commit('setResultCheck', 'iosInstall');
      }
      return false;
    }

    if (isAndroid) {
      if (!window.matchMedia('(display-mode: standalone)').matches) {
        commit('setResultCheck', 'androidInstall');
      }
      return false;
    }

  },
};

const mutations = {
  setResultCheck:(state, value) => state.DeviceReadyFor = value,
};

export default {
  state,
  getters,
  actions,
  mutations,
}
