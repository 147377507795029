const intToWordsMain = (k, d) => { // целое число прописью, это основа
  let i = '',
    e = [
      ['', 'тысяч', 'миллион', 'миллиард', 'триллион', 'квадриллион', 'квинтиллион', 'секстиллион', 'септиллион',
        'октиллион', 'нониллион', 'дециллион'
      ],
      ['а', 'и', ''],
      ['', 'а', 'ов']
    ];
  if (k === '' || parseInt(k,10) === 0) {
    return ' ноль';
  } // 0
  k = k.split(/(?=(?:\d{3})+$)/); // разбить число в массив с трёхзначными числами
  if (k[0].length === 1) k[0] = '00' + k[0];
  if (k[0].length === 2) k[0] = '0' + k[0];
  let out = '';
  for (let j = (k.length - 1); j >= 0; j--) { // соединить трёхзначные числа в одно число, добавив названия разрядов с окончаниями
    if (k[j] !== '000') {
      i = (((d && j === (k.length - 1)) || j === (k.length - 2)) && (k[j][2] === '1' || k[j][2] === '2') ? intToWordsChangeDigitPlace(k[j], 1) : intToWordsChangeDigitPlace(k[j])) + intToWordsDeclOfNum(k[j], e[0][k.length - 1 - j], (j === (k.length - 2) ? e[1] : e[2])) + i;
    }
  }
  return i;
};

const intToWordsChangeDigitPlace = (k, d) => { // преобразовать трёхзначные числа
  let e = [
    ['', ' один', ' два', ' три', ' четыре', ' пять', ' шесть', ' семь', ' восемь', ' девять'],
    [' десять', ' одиннадцать', ' двенадцать', ' тринадцать', ' четырнадцать', ' пятнадцать', ' шестнадцать',
      ' семнадцать', ' восемнадцать', ' девятнадцать'
    ],
    ['', '', ' двадцать', ' тридцать', ' сорок', ' пятьдесят', ' шестьдесят', ' семьдесят', ' восемьдесят',
      ' девяносто'
    ],
    ['', ' сто', ' двести', ' триста', ' четыреста', ' пятьсот', ' шестьсот', ' семьсот', ' восемьсот',
      ' девятьсот'
    ],
    ['', ' одна', ' две']
  ];
  return e[3][k[0]] + (k[1] === '1' ? e[1][k[2]] : e[2][k[1]] + (d ? e[4][k[2]] : e[0][k[2]]));
};
const intToWordsDeclOfNum = (n, t, o) =>{ // склонение именительных рядом с числительным: число (typeof = string),
  // корень (не
  // пустой), окончание
  let k = [2, 0, 1, 1, 1, 2, 2, 2, 2, 2];
  return (t === '' ? '' : ' ' + t + (n[n.length - 2] === "1" ? o[2] : o[k[n[n.length - 1]]]));
};

export default {
  timeFromXtoDateView: (value) => {
    if (value === undefined) {return '';}
    let arrValue = value.split('T');
    let arrDate = arrValue[0].split('-');
    let out = arrDate[2] + '.' + arrDate[1] + '.' + arrDate[0];
    return out;
  },
  timeFromXtoDateTimeView: (value) => {
    if (value === undefined || value === '') {
      return '';
    }
    let arrValue = value.split('T');
    let arrDate = arrValue[0].split('-');
    let timeArr = arrValue[1].split('.')
     return  arrDate[2] + '.' + arrDate[1] + '.' + arrDate[0] + ' ' + timeArr[0];
  },
  reverseDateFromX: (value) =>{
    if (value === undefined) return '';
    return value.toString().replace(/(\d{4}).(\d{2}).(\d{2})/, '$3.$2.$1');
  },
  moneyFormat: (value) => {
    if (value === undefined) {return '';}
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + '<span class="ALS">a</span>'; // wtf?
  },
  money: (value) => {
    if (value === undefined) {return '';}
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },
  humanViewPhone: (value) => {
    if (value === undefined) { return '';}
    return value.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '8 ($1) $2-$3-$4');
  },
  convertSecondsinMinAndSeconds:(value)=>{
    if (value === undefined) {return '';}
    let mins = Math.floor(value/60);
    let secs = Math.floor(value - mins*60);
    return mins + ':' + secs;
  },
  moneyIntlFormat: (value) => {
    return  new Intl.NumberFormat("ru-RU", {style: "currency", currency: "RUB"}).format(parseInt(value, 10));
  },
  moneyIntlFormatNumber: (value, option = {style: "currency", currency: "RUB"}) => {
    return  new Intl.NumberFormat("ru-RU", option).format(value);
  },
  demandWithZero: (value) => {
    if (value === undefined) { return '';}
  },
  intToWords(a) {
    a = Number(a).toFixed(2).split('.'); // округлить до сотых и сделать массив двух чисел: до точки и после неё
    let out = intToWordsMain(a[0],0) + intToWordsDeclOfNum(a[0], 'рубл', ['ь', 'я', 'ей']);
    if (a[1] !== undefined && a[1].length > 0) {
      out += intToWordsMain(a[1],1) + intToWordsDeclOfNum(a[1], 'копе', ['йка', 'йки', 'ек']);
    }
  return out[1].toUpperCase() + out.substring(2);
  },
  intToWordsNonMoney(a) {
    a = Number(a).toFixed(3).split('.'); // округлить до сотых и сделать массив двух чисел: до точки и после неё
    let out = intToWordsMain(a[0],0) + ' целых ' ;
    if (a[1] !== undefined && a[1].length > 0) {
      out += intToWordsMain(a[1],1)  + ' тысячных ';
    }
    return out[1].toUpperCase() + out.substring(2);
  }
}
