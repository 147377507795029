import Vue from 'vue'
import Router from 'vue-router'
import store from "@/store";

Vue.use(Router);

const getBaseData = async (to, from, next) => {
  if (store.getters.isAuthenticated) {
    store.dispatch('setIsLoading', true)
    try {
      await store.dispatch('updateContentData')
    } catch (e) {
      console.log(e.message ?? e)
      store.dispatch('setIsLoading', false)
      next('/logOut')
      return
    }
    store.dispatch('setIsLoading', false)
  }
  next()
}

const ifAuthenticated = (to, from, next) => store.getters.isAuthenticated ? next() : next('/login')

const ifNotAuthenticated = (to, from, next) => store.getters.isAuthenticated ? next('/') : next()


const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Главная',
      menuView: false,
      component: () => import('@/views/contractDetails'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/insurance',
      name: 'Страхование',
      menuView: true,
      component: () => import('@/views/insurance'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/how_to',
      name: 'Как быстро получить заём?',
      menuView: false,
      component: () => import('@/views/public/how_to'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/advantages',
      name: 'Наши преимущества',
      menuView: false,
      component: () => import('@/views/public/advantages'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/contacts',
      name: 'Контакты',
      menuView: false,
      component: () => import('@/views/public/contacts'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/how_to_repay',
      name: 'Как быстро погасить заём',
      menuView: false,
      component: () => import('@/views/public/how_to_repay'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/conditions',
      name: 'Условия',
      menuView: false,
      component: () => import('@/views/public/conditions'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/KID',
      name: 'KID_document',
      menuView: false,
      component: () => import('@/views/documents/KID_document.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/index.html',
      name: 'contractDetails',
      component: () => import('@/views/contractDetails'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/login',
      component: () => import('@/views/auth/login'),
      children: [
        { path: '', component: () => import('@/views/auth/LoginForm') },
        { path: 'recovery', component: () => import('@/views/auth/recoveryForm') },
        { path: 'signin', component: () => import('@/views/auth/SigninForm') },
        { path: 'ConfirmationCode', component: () => import('@/views/auth/ConfirmationCode') },
        {
          path: 'nsb', component: () => import('@/views/auth/login'), children: [
            { path: '', component: () => import('@/views/auth/nsb') },
            { path: 'recovery', component: () => import('@/views/auth/nsbRecoveryForm') },
            { path: 'signin', component: () => import('@/views/auth/nsbSigninForm') },
          ]
        },
        { path: 'signLink', component: () => import('@/views/auth/signLink') }
      ],
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/Contact',
      name: 'Контакты',
      menuView: false,
      component: () => import('@/views/Contact'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/PayGraphic',
      name: 'График платежей',
      menuView: true,
      component: () => import('@/views/PayGraphic'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/PayList',
      name: 'Фактические платежи',
      menuView: true,
      component: () => import('@/views/PayList'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/payment/:paymentBy',
      name: 'Оплата',
      menuView: route => {
        route.path = '/payment/current'
        route.menuView = true
      },
      component: () => import('@/views/payment/paymentRouting'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/paymentDlcode',
      name: 'Оплата по номеру договора',
      menuView: false,
      component: () => import('@/views/payment/dlRouting'),
    },
    {
      path: '/addRecomendation',
      name: 'Заработать',
      menuView: true,
      component: () => import('@/views/request/addRecomendation'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/android_IOS_Page',
      name: 'appDownload',
      menuView: false,
      component: () => import('@/views/banner/android_IOS_Page.vue'),
      // beforeEnter: ifAuthenticated,
    },
    {
      path: '/menuPage',
      name: 'menuPage',
      component: () => import('@/views/menuPage'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/kl',
      component: () => import('@/views/keepalive'),
      children: [
        { path: 'ChangePassword', component: () => import('@/views/ChangePassword') },
      ],
      menuView: false,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/pdl',
      component: () => import('@/views/pdl/pdl'),
      children: [
        { path: 'smslink', component: () => import('@/views/pdl/pdlEnter2') },
        { path: 'loanEnding2', component: () => import('@/views/pdl/pdlEnter2/loanEnding') },
        { path: 'entering', component: () => import('@/views/pdl/pdlEnter') },
        { path: 'loanEnding', component: () => import('@/views/pdl/loanEnding') },
        { path: 'loanBaseAwait', component: () => import('@/views/pdl/loanBaseAwait') },
        { path: 'prolongation', component: () => import('@/views/pdl/prolongation') },
      ]
    },
    {
      path: '/public',
      component: () => import('@/views/public'),
      children: [
        {path: 'agreementBoxes/:uid', component: () => import('@/views/agreement/agreementBoxes.vue') },
        {path: 'kari', component:()=>import('@/views/public/kariPromo.vue')},
        {path: 'boxNotifications/:uid', component: () => import('@/views/documents/boxNotifications.vue') },
        { path: 'anniversaryContestDescription', component: () => import('@/views/banner/anniversaryContestDescription.vue') },
        { path: 'promoPageFreePhone', component: () => import('@/views/banner/promoPageFreePhone.vue') },
        { path: 'promoPageLetoile', component: () => import('@/views/banner/promoPageLetoile.vue') },
        { path: 'promoPageGJ', component: () => import('@/views/banner/promoPageGJ.vue') },
        { path: 'promoMagnit', component: () => import('@/views/banner/promoMagnit.vue') },
        { path: 'confirm', component: () => import('@/views/agreement/confirm') },//иу для базового продукта
        {path:'pdnIl/:uid',component:()=>import('@/views/agreement/confirmPdnIl')},
        {path:'pdnPdl/:uid',component:()=>import('@/views/agreement/confirmPdnPdl')},
        // {path:'confirmold',component:()=>import('@/views/agreement/confirm_old')},
        { path: 'sendImageRequest', component: () => import('@/views/request/sendImg') },
        { path: 'forRedirectIos', component: () => import('@/views/public/forRedirectIos') },
        { path: 'alfaConsent', component: () => import('@/views/public/alfaConsent') },
        { path: 'pdl_IU', component: () => import('@/views/agreement/pdl_IU') },//иу для пдл
        { path:'chdp/:uid',component:()=>import('@/views/agreement/confirmChdp')},
        {path: 'pdlForm', component: () => import('@/views/agreement/pdlForm') },
        { path: 'info', component: () => import('@/views/public/info') },
        { path: 'ka/:uid', component: () => import('@/views/public/ka'), props: true },
        { path: 'cession/:uid', component: () => import('@/views/public/cession'), props: true },
        { path: 'inf', component: () => import('@/views/public/description') },
      ]
    },
    {
      path: '/logOut',
      name: 'Выход',
      menuView: false,
      component: () => import('@/views/logOut')
    },
    {
      path: '/repeatLoan',
      name: 'Повторный займ',
      menuView: false,
      component: () => import('@/views/repeatLoan/repeatLoan'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '*',
      component: () => import('@/views/pageNotFound')
    },
  ]
})
router.beforeResolve(getBaseData)

export default router;
