import axios from 'axios';

export default {
  consoleDebug: function (anyValue, group = undefined) {
    if (process.env.VUE_APP_CONSOLE === 'TRUE') {
      if (group !== undefined) {
        if (group === 'open') {
          console.group(anyValue);
        }
        if (group === 'close') {
          console.groupEnd(anyValue)
        }
      } else {
        console.log(anyValue);
      }
    }
  },
  selfRequest: function (sendedData) {
    sendedData['UserID'] = localStorage.UserID;
    sendedData['SessionKey'] = localStorage.SessionKey;
    sendedData['sessionid'] = localStorage.sessionid;
    sendedData['source'] = 'webLk';
    return new Promise((resolve, reject) => {
      this.consoleDebug('selfRequest start', 'open');
      axios({
        method: 'post',
        url: process.env.VUE_APP_URL_SELFREQUEST + sendedData.action,
        data: sendedData
      }).then((res) => {
        this.consoleDebug('selfRequest then');
        try {
          if (res.data.result.ExecutionError.ExecutionErrorCode === 0) {
            this.consoleDebug('selfRequest ловим данные');
            resolve(res.data.result.ExecutionResult);
          } else if (res.data.result.ExecutionError.ExecutionErrorCode > 0) {
            this.consoleDebug('selfRequest ошибка бека');
            reject(this.errorDigestion(res.data.result.ExecutionError.ExecutionErrorCode, res.data.result.ExecutionError.ExecutionErrorMessage))
          }
        } catch (resp) {
          reject(resp.data);
        }
      }).catch((res) => {
        this.consoleDebug('selfRequest catch');
        this.consoleDebug(res);
        reject(this.networkError(res, sendedData));
        // if (resp.data !== undefined) {
        //   reject(resp.data);
        // } else {
        //   reject(resp);
        // }
      });
      this.consoleDebug('selfRequest', 'close');
    });
  },

  gita: function (Hindi, Bindi) {
    return new Promise((resolve, reject) => {
      this.consoleDebug('gita', 'open');
      this.consoleDebug( 'Hindi ' + Hindi + ' ' + 'Bindi ' + Bindi );
      axios({
        method: 'post',
        url: process.env.VUE_APP_URL_GITA,
        data: {
          Hindi: Hindi,
          Bindi: Bindi
        }
      }).then((res)=>{
        this.consoleDebug('gita then');
        if (res.data.error !== undefined) {
          if (res.data.error.code === 0 || res.data.error.code === 1) {
            this.consoleDebug('работа гиты...');
            if ( res.data.error.code === 1 ) {
              setTimeout(() => {
                this.gita(Hindi, Bindi)
                    .then((res)=>{
                      resolve(res)
                    }).catch((res)=>{
                  reject(res)
                }).finally(()=>{
                  this.consoleDebug('gita', 'close');
                })
              }, 1000)
            } else {
              this.consoleDebug('ловим данные');
              // проверить ответ гиты на корректность
              this.resultCheckMonolit(res.data)
                  .then((res)=>{
                    this.consoleDebug('прошли проверку');
                    resolve(res);
                  }).catch((res)=>{
                this.consoleDebug('не прошли проверку');
                reject(res);
              }).finally(()=>{
                this.consoleDebug('gita', 'close');
              });
            }
          } else {
            this.consoleDebug('ошибка гиты')
            this.consoleDebug('gita', 'close');
            reject(this.errorDigestion(res.data.error.code, res.data.error.errorMessage));
          }
        } else if ( res.data.errorCode !== undefined ) {
          this.consoleDebug('gita', 'close');
          reject(this.PHPerrors(res))
        } else {
          reject(this.errorDigestion(78, 'пустой ответ сервера'))
        }
        this.consoleDebug('gita', 'close');
      }).catch((res)=>{
        this.consoleDebug('gita catch')
        this.consoleDebug(res)
        this.consoleDebug('gita', 'close');
        reject(this.networkError(res, {action: 'runGita', Hindi: Hindi, Bindi: Bindi}))
      }).finally(()=>{
        this.consoleDebug('gita', 'close');
      })
      this.consoleDebug('gita', 'close');
    })
  },

  resultCheckMonolit(resultFrom) {
    return new Promise((resolve, reject) => {
      this.consoleDebug('resultCheckMonolit', 'open');
      this.consoleDebug(resultFrom);
      if (resultFrom.result !== undefined) {
        if (resultFrom.result.ExecutionError !== undefined) {
          if ( resultFrom.result.ExecutionError.ExecutionErrorCode === 0 ) {
            if ( resultFrom.result.ExecutionResult !== undefined ) {
              resolve(resultFrom.result.ExecutionResult);
            } else {
              reject(this.errorDigestion(35, 'Отсутствует нода ExecutionResult'))
            }
          } else if (resultFrom.result.ExecutionError.ExecutionErrorCode === 401) {
            // разлогин
            document.location.href = '/logout';
            reject(this.errorDigestion(401, 'Необходимо заново авторизоваться'))
            return false;
          } else {
            reject(this.errorDigestion(resultFrom.result.ExecutionError.ExecutionErrorCode, resultFrom.result.ExecutionError.ExecutionErrorMessage))
          }
        } else {
          reject(this.errorDigestion(37,'Отсутствует нода ExecutionError'))
        }
      } else {
        reject( this.errorDigestion(39,'Отсутствует нода Result') )
      }
      this.consoleDebug('resultCheckMonolit', 'close');
    })
  },








  zita:function (sendedData){
    return new Promise((resolve, reject) => {
      this.consoleDebug('zita', 'open');
      this.consoleDebug(sendedData.action);
      sendedData['Bindi'] = localStorage.getItem('Bindi');
      sendedData['source'] = 'webLk';

      if(typeof sendedData['Bindi'] !== 'string') {
        sendedData['Bindi'] = this.uuidv4();
        localStorage.setItem('Bindi',sendedData['Bindi']);
      }

      // todo добавить тернарную операцию: если sessionKey === null, то сгенерировать какой-нибудь guid
      axios({
        method: 'post',
        url: process.env.VUE_APP_URL_ZITA + sendedData.action,
        data: sendedData,
      }).then((res) => {
        this.consoleDebug('then');
        this.consoleDebug(res);
        if (res.data.Error !== undefined ) {
          if (res.data.Error.Code === 0) {
            this.consoleDebug('всё хорошо, переходим к гите');
            setTimeout(() => {
              this.gita(res.data.Result, sendedData.Bindi).then((res)=>{
                resolve(res)
              }).catch((res)=>{
                reject(res)
              }).finally(()=>{
                this.consoleDebug('zita', 'close');
              })
            }, 1000);
          } else {
            this.consoleDebug('сервис ответил какой-то хернёй');
            reject(this.errorDigestion(res.data.Error.Code, res.data.Error.ErrorMessage + ' # ' + sendedData.action))
          }
        } else if (res.data.errorCode !== undefined && res.data.errorCode > 0) {
          this.consoleDebug('ПХП что-то не нравится');
          reject(this.PHPerrors(res));
        } else {
          reject(this.errorDigestion(69, 'пустой ответ сервера'))
        }

      }).catch((res) => {
        this.consoleDebug('zita catch');
        reject(this.networkError(res, sendedData))
      });
      this.consoleDebug('zita', 'close');
    });
  },

  universalRequest: function (sendedData) {
    return new Promise((resolve, reject) => {
      this.consoleDebug('universalRequest', 'open');
      sendedData['UserID'] = localStorage.getItem('UserID');
      sendedData['SessionKey'] = localStorage.getItem('SessionKey');
      sendedData['sessionid'] = localStorage.getItem('sessionid');
      if (sendedData.action === undefined || sendedData.action === '' || typeof sendedData.action !== 'string') {
        reject(this.errorDigestion(123, 'action не корректный' ));
        this.consoleDebug('universalRequest', 'close');
        return;
      }
      if ( typeof this[sendedData.externalType] == 'function' ) {
        this[sendedData.externalType](sendedData)
            .then((res)=>{
              resolve(res)
            }).catch((res)=>{
          reject(res)
        }).finally(()=>{
          this.consoleDebug('universalRequest', 'close');
        })
      } else {
        this.consoleDebug('universalRequest', 'close');
        reject(this.errorDigestion(333, 'метод такого запроса отсутствует в коде' ));
      }
    })
  },

  errorDigestion(code, message) {
    this.consoleDebug('errorDigestion start', 'open')
    this.consoleDebug(code);
    this.consoleDebug(message);
    this.consoleDebug('errorDigestion', 'close')
    return {errorCode: code, errorMsg: message}
  },

  PHPerrors:function (res) {
    this.consoleDebug('PHPerrors start', 'open')
    // let errCode = res.data.errorCode;
    // let errMessage = res.data.errorMsg;
    this.consoleDebug('PHPerrors', 'close')
    return this.errorDigestion(res.data.errorCode, res.data.errorMsg)
  },

  networkError: function(error, data) {
    this.consoleDebug('networkError start', 'open');
    let errMessage = ''
    let errCode = ''
    data.SessionKey = '';
    let dataJSON = JSON.stringify(data);
    if (error.response) {
      this.consoleDebug('Запрос был сделан, и сервер ответил кодом состояния, который выходит за пределы 2xx');
      errCode = error.response.status
      errMessage = 'Ошибка в коде, сервер ответил кодом состояния';

      if (data.action === 'login') {
        errCode = error.response.status
        errMessage = 'Ошибка в коде, сервер ответил кодом состояния' + ' ' + error.response.status + ' ' + 'в методе' + ' ' + data.action;

      } else {
        errCode = error.response.status
        errMessage = 'Ошибка в коде, сервер ответил кодом состояния' + ' ' + error.response.status + ' ' + 'в методе' + ' ' + data.action + ' ' + 'с данными' + ' ' + dataJSON;
      }

    } else if (error.request) {
      // `error.request`- это экземпляр XMLHttpRequest в браузере и экземпляр http.ClientRequest в node.js
      this.consoleDebug('Запрос был сделан, но ответ не получен');

      if (error.message === 'Network Error') {
        this.consoleDebug('нет инета')
        errCode = 666
        errMessage = 'Нет соединения с интернетом, операция не выполена';
      }
    } else {
      errCode = 345
      errMessage = 'Error: Произошло что-то при настройке запроса, вызвавшее ошибку'
      this.consoleDebug('Error: Произошло что-то при настройке запроса, вызвавшее ошибку');
    }
    this.consoleDebug('networkError', 'close');
    return this.errorDigestion(errCode, errMessage)
  },

  uuidv4: function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


}
