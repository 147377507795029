import store from '@/store';

export default {
  PASSPORT_SERIES: function (value) {
    if (value.length === 4) {
      // this.focusInput('PASSPORT_NUMBER');
      return {code: 0, msg: '', ShowError: false};
    } else {
      if (value.length === 0) {
        return {code: 1, msg: 'Обязательное поле', ShowError: true};
      } else {
        return {code: -1, msg: 'Не правильный номер', ShowError: true};
      }
    }
  },
  PASSPORT_NUMBER: function (value) {
    if (value.length === 6) {
      // this.focusInput('PASSPORT_DATE');
      return {code: 0, msg: '', ShowError: false};
    } else {
      if (value.length === 0) {
        return {code: 1, msg: 'Обязательное поле', ShowError: true};
      } else {
        return {code: -1, msg: 'Не правильно записан номер', ShowError: true};
      }
    }
  },
  PASSPORT_DATE: function (value) {
    return this.checkValidateInputDate(value);
  },
  PASSPORT_DEP_CODE: function (value) {
    if (value.length === 7) {
      return {code: 0, msg: '', ShowError: false};
    } else {
      if (value.length === 0) {
        return {code: 1, msg: 'Обязательное поле', ShowError: true};
      } else {
        return {code: -1, msg: 'Не правильно записан код', ShowError: true};
      }
    }
  },
  BIRTHDAY: function (value) {
    return this.checkValidateInputDate(value);
  },
  MEETING_DATE: function (value) {

    let out =  this.correctDate(value);
    if (out.code == 0) {
      const inputDate = new Date(value).toISOString().slice(0, 10);
      const currentDate = new Date().toISOString().slice(0, 10);

      if (inputDate < currentDate) {
        return {code: -1, msg: 'Не корректная дата, не может быть меньше текущей', ShowError: true}
      }
    }
    return out;
  },
  MEETING_HOUR: function (value) {
    if (value.length == 0) {
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    }
    return this.correctTime(value);
  },
  isEmpty: function (value) {

    if (value.length === 0) {
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    } else {
      return {code: 0, msg: '', ShowError: false};
    }

  },
  IS_ADDRESS_COINCIDES: function (value) {
    if (value == 'Y' || value == 'N') {
      return {code: 0, msg: '', ShowError: false};
    } else {
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    }
  },
  correctDate: function (value) {
    //todo разница лет, для проверки минимального и максимального возрасата заявки
    //todo фиксированный год в условие отстой
    let out = {code: 1, msg: '', ShowError: false};
    if (!/^([0-9]{4,4})+\.([0-9]{2,2})+\.[0-9]{2,2}$/.test(value)) {
      out = {code: 0, msg: '', ShowError: false};
    }
    if (value.length > 10) {
      out = {code: -1, msg: 'Не корректная дата', ShowError: true}
    }
    let valueArr = value.split('-');
    let year = new Date();
    if (valueArr[0] > (year.getFullYear() + 1) || valueArr[0] < '1930') {
      out = {code: -1, msg: 'Не верно указан год', ShowError: true}
    }

    return out;
  },
  checkValidateInputDate: function (value) {
    let out = { code: 0, msg: '', ShowError: false };

    let parts = value.split('.');

    if (parts.length === 3) {
      let day = parseInt(parts[0], 10);
      let month = parseInt(parts[1], 10);
      let year = parseInt(parts[2], 10);

      // Сheck, valid dd,mm.yyyy correctly date
      if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
        let dateObj = new Date(year, month - 1, day);
          // Сheck, correctly date
        let currentYaer = new Date().getFullYear();
        if (!isNaN(dateObj) && dateObj.getDate() === day
          && dateObj.getMonth() === month - 1
          && dateObj.getFullYear() === year
          && dateObj.getFullYear() <= currentYaer
          && dateObj.getFullYear() > (currentYaer - 100)
        ) {
          // Formatting date in "DD.MM.YYYY"
          // `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
          return out;
        } else {
          out = { code: -1, msg: 'Не корректная дата', ShowError: true };
        }
      } else {
        out = { code: -1, msg: 'Не корректная дата', ShowError: true };
      }
    } else {
      out = { code: -1, msg: 'Не корректная дата', ShowError: true };
    }

    return out;
  },
  correctTime: function (value) {
    let out = {code: 0, msg: '', ShowError: false};
    if (!/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value)) {
      out = {code: -1, msg: 'Не корректное время', ShowError: true};
    }
    return out;
  },
  focusInput: function (target) {
    let domEl = document.getElementsByName(target);
    if (domEl.length > 0) {
      domEl[0].focus();
    }
  },
  // PHONEmaskKostil:function(value){
  //   console.log(value);
  //   return value;
  // },
  FIRST_NAMEmaskKostil: function (value) {
    return this.fioSprMask(value);
  },
  LAST_NAMEmaskKostil: function (value) {
    return this.fioSprMask(value);
  },
  SECOND_NAMEmaskKostil: function (value) {
    return this.fioSprMask(value);
  },
  CAR_NUMBERmaskKostil: function (val) {
    //сканим строку с номером, проверяем по индексу в строку на соответсвие правилам, если все ок формируем новую строку, исходную проебываем.
    let newVal = '',
      libSwitchEn = ['A', 'B', 'E', 'K', 'M', 'H', 'O', 'P', 'C', 'T', 'Y', 'X'],
      libSwitchRu = ['А', 'В', 'Е', 'К', 'М', 'Н', 'О', 'Р', 'С', 'Т', 'У', 'Х'],
      numberSymbol = /^[0-9]$/;
    val = val.toUpperCase();
    // console.log(val)
//todo переписать на покрасивще!
    for (let i = 0; i < val.length; i += 1) {
      switch (i) {
        case 0:
          if (libSwitchEn.indexOf(val[i]) > 0) {
            newVal += libSwitchRu[libSwitchEn.indexOf(val[i])];
          }
          if (libSwitchRu.indexOf(val[i]) > 0) {
            newVal += val[i];
          }
          break;
        case 1:
          if (numberSymbol.test(val[i])) {
            newVal += val[i]
          }
          break;
        case 2:
          if (numberSymbol.test(val[i])) {
            newVal += val[i]
          }
          break;
        case 3:
          if (numberSymbol.test(val[i])) {
            newVal += val[i]
          }
          break;
        case 4:
          if (libSwitchEn.indexOf(val[i]) > 0) {
            newVal += libSwitchRu[libSwitchEn.indexOf(val[i])];
          }
          if (libSwitchRu.indexOf(val[i]) > 0) {
            newVal += val[i];
          }
          break;
        case 5:
          if (libSwitchEn.indexOf(val[i]) > 0) {
            newVal += libSwitchRu[libSwitchEn.indexOf(val[i])];
          }
          if (libSwitchRu.indexOf(val[i]) > 0) {
            newVal += val[i];
          }
          break;
        case 6:
          if (numberSymbol.test(val[i]) && parseInt(val[i], 10) > 1) {
            newVal += val[i];
          }
          break;
        case 7:
          if (numberSymbol.test(val[i])) {
            newVal += val[i]
          }
          break;
        case 8:
          if (numberSymbol.test(val[i])) {
            newVal += val[i]
          }
          break;
      }
    }
    // console.log(newVal)
    return newVal;
  },
  fioSprMask: function (value) {
    let mapKey = {
      'q': 'й',
      'w': 'ц',
      'e': 'у',
      'r': 'к',
      't': 'е',
      'y': 'н',
      'u': 'г',
      'i': 'ш',
      'o': 'щ',
      'p': 'з',
      '[': 'х',
      ']': 'ъ',
      'a': 'ф',
      's': 'ы',
      'd': 'в',
      'f': 'а',
      'g': 'п',
      'h': 'р',
      'j': 'о',
      'k': 'л',
      'l': 'д',
      ';': 'ж',
      'z': 'я',
      'x': 'ч',
      'c': 'с',
      'v': 'м',
      'b': 'и',
      'n': 'т',
      'm': 'ь',
      'Q': 'Й',
      'W': 'Ц',
      'E': 'У',
      'R': 'К',
      'T': 'Е',
      'Y': 'Н',
      'U': 'Г',
      'I': 'Ш',
      'O': 'Щ',
      'P': 'З',
      '{': 'Х',
      '}': 'Ъ',
      'A': 'Ф',
      'S': 'Ы',
      'D': 'В',
      'F': 'А',
      'G': 'П',
      'H': 'Р',
      'J': 'О',
      'K': 'Л',
      'L': 'Д',
      ':': 'Ж',
      '\'': 'Э',
      'Z': 'Я',
      'X': 'Ч',
      'C': 'С',
      'V': 'М',
      'B': 'И',
      'N': 'Т',
      'M': 'Ь',
      ',': 'Б',
      '<': 'Б',
      '.': 'Ю',
      '>': 'Ю',
    };
    let str = value.toUpperCase();
    let r = '';
    for (var i = 0; i < str.length; i++) {
      r += mapKey[str.charAt(i)] || str.charAt(i);
    }
    return r.replace(/[^А-Яа-яЁё -]/gi, '').replace(/\s+/gi, ' ').replace(/[-+]/gi, '-');
  },
  ReCallDate: function (value) {
    if (value.length == 0) {
      return {code: 0, msg: '', ShowError: false};
    } else {
      return this.correctDate(value);
    }

  },
  ReCallTime: function (value) {
    if (value.length == 0) {
      return {code: 0, msg: '', ShowError: false};
    } else {
      return this.correctTime(value);
    }
  },
  // eslint-disable-next-line no-unused-vars
  CALC_SUMM: function (value) {
    // console.log(value);
    return {code: 0, msg: '', ShowError: false};
  },
  // eslint-disable-next-line no-unused-vars
  REGISTRATION_ADDRESS_DADATA: function (value) {

    return {code: 0, msg: '', ShowError: false};
  },
  // eslint-disable-next-line no-unused-vars
  RESIDENTIAL_ADDRESS_DADATA: function (value) {

    return {code: 0, msg: '', ShowError: false};
  },
  CAR_NUMBER: function (value) {
    if (store.getters.getTargetFieldValue('PRODUCT_ID') == 'A') {
      let req = /^[АВЕКМНОРСТУХ]\d{3}[АВЕКМНОРСТУХ]{2}\d{2,3}$/ui;

      if (value.length == 0) {
        return {code: 1, msg: 'Обязательное поле', ShowError: true};
      }

      if (!req.test(value)) {
        return {code: 1, msg: 'Некорректные символы в номере', ShowError: true};
      }

      return {code: 0, msg: '', ShowError: false};
    } else {
      return {code: 0, msg: '', ShowError: false};
    }

  },
  PRODUCT_ID: function (value) {
    setTimeout(() => {
      let car_number = store.getters.getTargetFieldValue('CAR_NUMBER');
      store.commit('saveWorkData', {
        form: 'request',
        target: 'CAR_NUMBER',
        status: this.CAR_NUMBER(car_number),
      });
    }, 30);

    if (value.length == 0) {
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    }
    return {code: 0, msg: '', ShowError: false};

  },
  PHONE: function (value) {
// console.log(value)
    if (value.length == 0) {
      // console.log(11)
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    }
    let chek = value.replace(/\D+/g, "");
    if (chek.length != 11) {
      // console.log(12)
      return {code: 1, msg: 'Некорректный номер телефона', ShowError: true};
    }
    if (chek[0] == '7' && chek[1] != '9') {
      // console.log(13)
      return {code: 1, msg: 'Некорректный код телефона', ShowError: true};
    }
    // console.log(14)
    return {code: 0, msg: '', ShowError: false};
  },
  OTHER_SOURCES_OF_INCOME: function (value) {
    if (value.length == 0) {
      return {code: 1, msg: 'Обязательное поле, укажите 0 если нет дополнительного дохода', ShowError: true};
    }
    if (parseInt(value,10)!= 0 && parseInt(value,10) < 1000|| parseInt(value,10) >= 1000000) {
      return {code: 1, msg: 'не корректная сумма', ShowError: true};
    }
    return {code: 0, msg: '', ShowError: false};
  },
  CONST_INCOME: function (value) {
    if (value.length == 0) {
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    }
    if (value[0] == '0') {
      return {code: 1, msg: 'не корректная сумма', ShowError: true};
    }
    if (parseInt(value,10) < 1000 || parseInt(value,10) >= 1000000) {
      return {code: 1, msg: 'не корректная сумма', ShowError: true};
    }
    return {code: 0, msg: '', ShowError: false};
  },
  ASP:function(value){
    return value ? {code: 0, msg: '', ShowError: false} : {code: 1, msg: 'Обязательное поле', ShowError: true} ;
  },
  AVERAGE_EXPENSES_PER_MONTH: function (value) {
    if (value.length == 0) {
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    }
    if (value[0] == '0') {
      return {code: 1, msg: 'не корректная сумма', ShowError: true};
    }
    if (parseInt(value,10) < 0 || parseInt(value,10) >= 1000000) {
      return {code: 1, msg: 'не корректная сумма', ShowError: true};
    }
    return {code: 0, msg: '', ShowError: false};
  },
  AVERAGE_MONTHLY_EXPENSES_FOR_LOAN_PAYMENTS: function (value) {
    if (value.length == 0) {
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    }

    if (parseInt(value,10) < 0 || parseInt(value,10) >= 1000000) {
      return {code: 1, msg: 'не корректная сумма', ShowError: true};
    }
    return {code: 0, msg: '', ShowError: false};
  },
  checkInnDigit(inn, coefficients) {
    let n = 0;
    for (let i in coefficients) {
      n += coefficients[i] * inn[i];
    }
    return parseInt(n % 11 % 10);
  },
  SNILS:function(snils){
      snils = snils.replace(/[^0-9]/g,"");
      if (typeof snils === 'number') {
        snils = snils.toString();
      } else if (typeof snils !== 'string') {
        snils = '';
      }
      if (!snils.length) {
        return {code: 0, msg: '', ShowError: false};
      } else if (/[^0-9]/.test(snils)) {
        return {code: 1, msg: 'СНИЛС может состоять только из цифр', ShowError: true};
      } else if (snils.length !== 11) {
        return {code: 1, msg: 'СНИЛС может состоять только из 11 цифр', ShowError: true};
      } else {
        let sum = 0;
        for (let i = 0; i < 9; i++) {
          sum += parseInt(snils[i],10) * (9 - i);
        }
        let checkDigit = 0;
        if (sum < 100) {
          checkDigit = sum;
        } else if (sum > 101) {
          checkDigit = parseInt(sum % 101,10);
          if (checkDigit === 100) {
            checkDigit = 0;
          }
        }
        if (checkDigit === parseInt(snils.slice(-2))) {
          return {code: 0, msg: '', ShowError: false};
        } else {
          return {code: 1, msg: 'СНИЛС не корректный', ShowError: true};
        }
      }
  },
  INN:function (inn){
    let result = false;
    if (typeof inn === 'number') {
      inn = inn.toString();
    } else if (typeof inn !== 'string') {
      inn = '';
    }

    // if (!inn.length) {
    //   this.err.Inn = 'ИНН пуст';
    // } else
    if (inn.length > 0) {
      if (/[^0-9]/.test(inn)) {
        return {code: 1, msg: 'ИНН может состоять только из цифр', ShowError: true};
      } else if ([10, 12].indexOf(inn.length) === -1) {
        return {code: 1, msg: 'ИНН может состоять только из 10 или 12 цифр', ShowError: true};
      } else {
        switch (inn.length) {
          case 10:
            var n10 = this.checkInnDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if (n10 === parseInt(inn[9], 10)) {
              result = true;
            }
            break;
          case 12:
            var n11 = this.checkInnDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            var n12 = this.checkInnDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if ((n11 === parseInt(inn[10], 10)) && (n12 === parseInt(inn[11], 10))) {
              result = true;
            }
            break;
        }
        if (!result) {
          return {code: 1, msg: 'ИНН не корректный', ShowError: true};
        }
      }
    }
    return {code: 0, msg: '', ShowError: false};
  },
  CHECKBOX_SELECT:function(value) {
    return value ? {code: 0, msg: ' ', ShowError: false} : {code: 1, msg: 'Обязательный пункт', ShowError: true}
  },
  NOT_RELATED:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  MY_OWN_BENEFIT:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  ACTIONS_CONTROL:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  THIRD_PARTIES:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  ACCEPTANCE_OF_THE_TERMS:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  AGREEMENT_WITH_THE_RULES:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  AGREEMENT_LONGATSIA_RULES:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  AGREEMENT_DATA_TRANSMISSION:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  AGREEMENT_KI_REQUEST:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  GENERAL_TERMS_OF_MICROLOAN:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  RULES_FOR_MICROLOAN:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  APPLICATION_MICROLOAN:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  AGREEMENT_IU:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  LOAN_WITH_INSURANCE:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  REQURENT_PAY:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  MINOR_CHILDREN:function (value) {
    return this.CHECKBOX_SELECT(value)
  },
  // todo не показываем - надо бы убрать
  WORK_JOB_INN:function (inn) {
    return this.INN(inn);
  },
  WORK_JOB_PHONE:function (phone) {
    return this.PHONE(phone);
  },
  CONTACT:function (value) {
    return this.isEmpty(value);
  },
  CONTACT_PHONE:function (value) {
    return this.PHONE(value);
  },
  //
  EMAILreq:function (value) {
    if (value.length === 0 ){
      return {code: 1, msg: 'обязательное поле', ShowError: true};
    }

    const emailRegx = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    if(emailRegx.test(value)){
      return {code: 0, msg: '', ShowError: false};
    } else {
      return {code: 1, msg: 'Некорректный email', ShowError: true};
    }
  },
  IU_FACE_AGREEMENT:function (value) {
    return this.ASP(value)
  }
}
