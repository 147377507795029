import FormFieldCheck from './requestFormFieldCheck';
import myApi from '@/gavnoCode/ApiHandler';
import { getDeal, loanDraftChangeScreen, loanDraftPdlConfirmCodeCheck, loanDraftSaveBaseProduct, getClientInfo } from '@/api/repeatLoanApi';
import external from '@/gavnoCode/external';

import tracing from '@/common/tracing';

import { TestRefs } from './testData';

const getDefaultState = () => {
  return {
    sendData: {
      AVERAGE_EXPENSES_PER_MONTH: '',
      AVERAGE_MONTHLY_EXPENSES_FOR_LOAN_PAYMENTS: '',
      BIRTHDAY: '',
      BIRTHPLACE: '',
      CALC_SUMM: '',
      CALC_WEEK: 52,
      CAR_NUMBER: '',
      CONST_INCOME: '',
      CREDHISTORY_RADIO: '',
      EDUCATION: '',
      MARITAL_STATUS: '',
      MINOR_CHILDREN: false,
      EMAIL: '',
      EMAILreq: '',
      EXPER_RADIO: '',
      FIRST_NAME: '',
      GENDER: '',
      IS_ADDRESS_COINCIDES: 'N',
      IS_I_CERTIFY_REGISTRATION: 'Y',
      IS_I_CERTIFY_MARKETING: 'Y',
      IS_ASSIGNMENT_RIGHT: true,
      LAST_NAME: '',
      MEETING_DATE: '',
      MEETING_HOUR: '',
      OTHER_SOURCES_OF_INCOME: 0,
      PASSPORT_DATE: '',
      PASSPORT_DEP: '',
      PASSPORT_DEP_CODE: '',
      PASSPORT_NUMBER: '',
      PASSPORT_SERIES: '',
      PHONE: '+7 (',
      PRODUCT_ID: 'S',
      REGISTRATION_ADDRESS: '',
      REGISTRATION_ADDRESS_DADATA: {
        data: {
          postal_code: '',
          country: '',
          country_iso_code: '',
          federal_district: '',
          region_fias_id: '',
          region_kladr_id: '',
          region_iso_code: '',
          region_with_type: '',
          region_type: '',
          region_type_full: '',
          region: '',
          area_fias_id: '',
          area_kladr_id: '',
          area_with_type: '',
          area_type: '',
          area_type_full: '',
          area: '',
          city_fias_id: '',
          city_kladr_id: '',
          city_with_type: '',
          city_type: '',
          city_type_full: '',
          city: '',
          city_area: '',
          city_district_fias_id: '',
          city_district_kladr_id: '',
          city_district_with_type: '',
          city_district_type: '',
          city_district_type_full: '',
          city_district: '',
          settlement_fias_id: '',
          settlement_kladr_id: '',
          settlement_with_type: '',
          settlement_type: '',
          settlement_type_full: '',
          settlement: '',
          street_fias_id: '',
          street_kladr_id: '',
          street_with_type: '',
          street_type: '',
          street_type_full: '',
          street: '',
          house_fias_id: '',
          house_kladr_id: '',
          house_type: '',
          house_type_full: '',
          house: '',
          block_type: '',
          block_type_full: '',
          block: '',
          flat_type: '',
          flat_type_full: '',
          flat: '',
          flat_area: '',
          square_meter_price: '',
          flat_price: '',
          postal_box: '',
          fias_id: '',
          fias_code: '',
          fias_level: '',
          fias_actuality_state: '',
          kladr_id: '',
          geoname_id: '',
          capital_marker: '',
          okato: '',
          oktmo: '',
          tax_office: '',
          tax_office_legal: '',
          timezone: '',
          geo_lat: '',
          geo_lon: '',
          beltway_hit: '',
          beltway_distance: '',
          metro: '',
          qc_geo: '',
          qc_complete: '',
          qc_house: '',
          history_values: '',
          unparsed_parts: '',
          source: '',
          qc: ''
        }
      },
      RESIDENTIAL_ADDRESS: '',
      RESIDENTIAL_ADDRESS_DADATA: {
        data: {
          postal_code: '',
          country: '',
          country_iso_code: '',
          federal_district: '',
          region_fias_id: '',
          region_kladr_id: '',
          region_iso_code: '',
          region_with_type: '',
          region_type: '',
          region_type_full: '',
          region: '',
          area_fias_id: '',
          area_kladr_id: '',
          area_with_type: '',
          area_type: '',
          area_type_full: '',
          area: '',
          city_fias_id: '',
          city_kladr_id: '',
          city_with_type: '',
          city_type: '',
          city_type_full: '',
          city: '',
          city_area: '',
          city_district_fias_id: '',
          city_district_kladr_id: '',
          city_district_with_type: '',
          city_district_type: '',
          city_district_type_full: '',
          city_district: '',
          settlement_fias_id: '',
          settlement_kladr_id: '',
          settlement_with_type: '',
          settlement_type: '',
          settlement_type_full: '',
          settlement: '',
          street_fias_id: '',
          street_kladr_id: '',
          street_with_type: '',
          street_type: '',
          street_type_full: '',
          street: '',
          house_fias_id: '',
          house_kladr_id: '',
          house_type: '',
          house_type_full: '',
          house: '',
          block_type: '',
          block_type_full: '',
          block: '',
          flat_type: '',
          flat_type_full: '',
          flat: '',
          flat_area: '',
          square_meter_price: '',
          flat_price: '',
          postal_box: '',
          fias_id: '',
          fias_code: '',
          fias_level: '',
          fias_actuality_state: '',
          kladr_id: '',
          geoname_id: '',
          capital_marker: '',
          okato: '',
          oktmo: '',
          tax_office: '',
          tax_office_legal: '',
          timezone: '',
          geo_lat: '',
          geo_lon: '',
          beltway_hit: '',
          beltway_distance: '',
          metro: '',
          qc_geo: '',
          qc_complete: '',
          qc_house: '',
          history_values: '',
          unparsed_parts: '',
          source: '',
          qc: ''
        }
      },
      tinkoffE2C_cardSelected: {},
      tinkoffA2C_bankSelected: {},
      SECOND_NAME: '',
      WORK_JOB: '',
      WHERE_TO_FIND_US: '',
      REFFER: 'C сайта',
      SOURCE_OF_INCOME: '',
      PURPOSE_OF_THE_LOAN: '',
      FINANCIAL_POSITION: '',
      SmsConfCode: '',
      ASP: true,
      INN: '',
      // todo не показываем - надо бы убрать
      WORK_JOB_INN: '',
      WORK_JOB_PHONE: '',
      CONTACT: '',
      CONTACT_PHONE: '',
      //
      PAYMENT_TYPE: '',
      IU_FACE_AGREEMENT: false,
      SNILS: '',
      availablePeriods: '',
      finderBankSbp: '',
      pdlSmsConfCode: '',
      NOT_RELATED: true,
      MY_OWN_BENEFIT: true,
      ACTIONS_CONTROL: true,

      THIRD_PARTIES: false,
      ACCEPTANCE_OF_THE_TERMS: false,
      AGREEMENT_WITH_THE_RULES: false,
      AGREEMENT_LONGATSIA_RULES: false,
      AGREEMENT_DATA_TRANSMISSION: false,
      AGREEMENT_KI_REQUEST: false,

      GENERAL_TERMS_OF_MICROLOAN: false,
      RULES_FOR_MICROLOAN: false,
      APPLICATION_MICROLOAN: false,
      AGREEMENT_IU: false,
      AGREEMENT_PDN:false,
      LOAN_WITH_INSURANCE: false,

      REQURENT_PAY: false,
      smartIdFileID: 0
    },
    workData: {
      AVERAGE_EXPENSES_PER_MONTH: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      AVERAGE_MONTHLY_EXPENSES_FOR_LOAN_PAYMENTS: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      AGREEMENT_PDN: {code:0,msg:'',ShowError:false},
      BIRTHDAY: { code: 1, msg: 'обязательное поле', ShowError: false },
      BIRTHPLACE: { code: 1, msg: 'обязательное поле', ShowError: false },
      CALC_SUMM: { code: 0, msg: 'обязательное поле', ShowError: false },
      CALC_WEEK: { code: 0, msg: '', ShowError: false },
      CAR_NUMBER: { code: 0, msg: '', ShowError: false },
      IS_ASSIGNMENT_RIGHT: { code: 0, msg: '', ShowError: false },
      CONST_INCOME: { code: 1, msg: 'обязательное поле', ShowError: false },
      CREDHISTORY_RADIO: { code: 0, msg: '', ShowError: false },
      EDUCATION: { code: 1, msg: 'обязательное поле', ShowError: false },
      MARITAL_STATUS: { code: 1, msg: 'обязательное поле', ShowError: false },
      MINOR_CHILDREN: { code: 0, msg: '', ShowError: false },
      EMAIL: { code: 1, msg: 'обязательное поле', ShowError: false },
      EXPER_RADIO: { code: 1, msg: 'обязательное поле', ShowError: false },
      FIRST_NAME: { code: 1, msg: 'обязательное поле', ShowError: false },
      GENDER: { code: 1, msg: 'обязательное поле', ShowError: false },
      IS_ADDRESS_COINCIDES: { code: 0, msg: '', ShowError: false },
      IS_I_CERTIFY_REGISTRATION: { code: 0, msg: '', ShowError: false },
      IS_I_CERTIFY_MARKETING: { code: 0, msg: '', ShowError: false },
      LAST_NAME: { code: 1, msg: 'обязательное поле', ShowError: false },
      MEETING_DATE: { code: 1, msg: 'обязательное поле', ShowError: false },
      MEETING_HOUR: { code: 1, msg: 'обязательное поле', ShowError: false },
      OTHER_SOURCES_OF_INCOME: { code: 0, msg: '', ShowError: false },
      PASSPORT_DATE: { code: 1, msg: 'обязательное поле', ShowError: false },
      PASSPORT_DEP: { code: 1, msg: 'обязательное поле', ShowError: false },
      PASSPORT_DEP_CODE: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      PASSPORT_NUMBER: { code: 1, msg: 'обязательное поле', ShowError: false },
      PASSPORT_SERIES: { code: 1, msg: 'обязательное поле', ShowError: false },
      PHONE: { code: 1, msg: 'обязательное поле', ShowError: false },
      PRODUCT_ID: { code: 0, msg: '', ShowError: false },
      REGISTRATION_ADDRESS: {
        code: 1,
        msg: 'обязательное поле, адрес нужно выбрать из выпадющего списка',
        ShowError: false
      },
      REGISTRATION_ADDRESS_DADATA: {
        code: 0,
        msg: 'обязательное поле',
        ShowError: false
      },
      RESIDENTIAL_ADDRESS: {
        code: 1,
        msg: 'обязательное поле, адрес нужно выбрать из выпадющего списка',
        ShowError: false
      },
      RESIDENTIAL_ADDRESS_DADATA: {
        code: 0,
        msg: 'обязательное поле',
        ShowError: false
      },
      SECOND_NAME: { code: 1, msg: 'обязательное поле', ShowError: false },
      WORK_JOB: { code: 1, msg: 'обязательное поле', ShowError: false },
      WHERE_TO_FIND_US: { code: 0, msg: '', ShowError: false },
      REFFER: { code: 0, msg: '', ShowError: false },
      SmsConfCode: { code: 1, msg: 'обязательное поле', ShowError: false },
      SOURCE_OF_INCOME: { code: 1, msg: 'обязательное поле', ShowError: false },
      PURPOSE_OF_THE_LOAN: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      FINANCIAL_POSITION: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      ASP: { code: 0, msg: '', ShowError: false },
      INN: { code: 0, msg: '', ShowError: false },
      // todo не показываем - надо бы убрать
      WORK_JOB_INN: { code: 0, msg: '', ShowError: false },
      WORK_JOB_PHONE: { code: 0, msg: '', ShowError: false },
      CONTACT: { code: 0, msg: '', ShowError: false },
      CONTACT_PHONE: { code: 0, msg: '', ShowError: false },
      //
      PAYMENT_TYPE: { code: 1, msg: 'обязательное поле', ShowError: false },
      tinkoffE2C_cardSelected: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      tinkoffA2C_bankSelected: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      IU_FACE_AGREEMENT: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      SNILS: { code: 0, msg: '', ShowError: false },
      finderBankSbp: { code: 0, msg: '', ShowError: false },
      pdlSmsConfCode: { code: 1, msg: 'обязательное поле', ShowError: false },
      MY_OWN_BENEFIT: { code: 0, msg: '', ShowError: false },
      ACTIONS_CONTROL: { code: 0, msg: '', ShowError: false },
      NOT_RELATED: { code: 0, msg: '', ShowError: false },

      THIRD_PARTIES: { code: 1, msg: 'обязательное поле', ShowError: false },
      ACCEPTANCE_OF_THE_TERMS: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      AGREEMENT_WITH_THE_RULES: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      AGREEMENT_LONGATSIA_RULES: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      AGREEMENT_DATA_TRANSMISSION: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      AGREEMENT_KI_REQUEST: {
        code: 0,
        msg: '',
        ShowError: false
      },

      GENERAL_TERMS_OF_MICROLOAN: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      RULES_FOR_MICROLOAN: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      APPLICATION_MICROLOAN: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      AGREEMENT_IU: { code: 1, msg: 'обязательное поле', ShowError: false },
      LOAN_WITH_INSURANCE: {
        code: 1,
        msg: 'обязательное поле',
        ShowError: false
      },
      REQURENT_PAY: { code: 1, msg: 'обязательное поле', ShowError: false },
      smartIdFileID: { code: 0, msg: '', ShowError: false }
    },
    system: {
      loading: false,
      availableScreens: ['calc', 'fio', 'payment', 'signingLoan'],
      systemScreens: ['waiting', 'rejection', 'thankYouPage', 'fullFail', 'cathError'],
      currentScreen: 'calc',
      nextScreen: undefined,
      scrMsg:
        'Извините, произошел технический сбой. Наши специалисты уже занимаются решением проблемы. Попробуйте' +
        ' заполнить заявку позже.',
      isError: false,
      errorMessage: '',
      geoTimer: undefined,
      loanDemandId: undefined,
      smsGuid: undefined,
      TraceId: undefined,
      longId: 0,

      // guid: null,
      // Partner_Id: null,

      // // то, что ниже точно систем?
      // onItsWay: false,
      // // loanid:204058,
      // isPDL: false,
      // DealProductType: false,
      // pdlLimit: 0,
      // loanLimit: 0
    },
    deal: {
      all: [],
      active: [],
    },
    tracing: {
      session_id: undefined,
      token: undefined
    },
    refs: {},
    geo: {
      isLoading: false,
      last: '',
      data: undefined,
      status: true
    }
  };
};

// function transformValueBeforeSave(workArr) {
//   let phoneFieldArr = ['PHONE', 'PHONE_ADDITIONAL', 'PHONE_FOR_CALL'];

//   if (phoneFieldArr.includes(workArr.target)) {
//     // let str = workArr.value;
//     // workArr.value = '+7 (' + str.slice(0,3) + ') ' + str.slice(3,6) + '-' + str.slice(6,8) + '-'  + str.slice(8,10);
//   }

//   return workArr.value;
// }

const state = getDefaultState();

const getters = {
  // getActiveDistinctPairs: (state) => {
  //   // работает пока сумма кратна 1000, а период - двузначное число
  //   return new Set(
  //     state.deal.active.map(
  //       ({ Amount, loanPeriod }) =>
  //         parseInt(Amount, 10) + parseInt(loanPeriod, 10)
  //     )
  //   );
  // },

  getActiveDistinctAmounts: (state) => {
    const distinctAmounts = new Set(
      state.deal.active.filter(x => x.IsVisible === '1').map(({ Amount }) => parseInt(Amount))
    );
    return [...distinctAmounts]
      .sort((a, b) => a - b)
      .map((num) => ({
        value: num,
        sort: num,
        name: num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }));
  },

  getActiveDistinctPeriods: (state) => {
    const distinctAmounts = new Set(
      state.deal.active.filter(x => x.IsVisible === '1').map(({ loanPeriod }) => parseInt(loanPeriod, 10))
    );
    return [...distinctAmounts]
      .sort((a, b) => a - b)
      .map((num) => ({
        value: num,
        sort: num,
        name: num < 50 ? num + ' дней' : num + ' недель'
      }));
  },


  getActiveDistinctAmountValues: (_, getters) =>
    getters.getActiveDistinctAmounts.map((x) => x.value),
  getActiveMinDeal: (_, getters) =>
    Math.min(...getters.getActiveDistinctAmountValues),
  getActiveMaxDeal: (_, getters) =>
    Math.max(...getters.getActiveDistinctAmountValues),

  currentScreenNumber: (state) => {
    return state.system.availableScreens.indexOf(state.system.currentScreen);
  },
  screenCount: (state) => state.system.availableScreens.length,

  getSession: (state) => ({ session_id: state.tracing.session_id, token: state.tracing.token }),
  hasSession: (state) => (!!state.tracing.session_id && !!state.tracing.token),

  isPdl: (state,getters) => getters.getSelectedDeal.vwIsPdl === '1',

  showSteps: (state) => ![...state.system.systemScreens, 'calc'].includes(state.system.currentScreen),

  userCanPdl: (state, getters, rootState) => rootState.contentData.aggInfo.find(x => x.Product === 'Pdl')?.Count === 0,
  userCanBase: (state, getters, rootState) => rootState.contentData.aggInfo.find(x => x.Product === 'Base')?.Count === 0 && state.geo.status,

  getActiveBoxDeal: (state) => {
    let out = [];
    let stepBoxSumm = 0;
    for (let i = 0; i < state.deal.active.length; i += 1) {
      if (parseInt(state.deal.active[i].loanPeriod, 10) === state.sendData.CALC_WEEK && state.deal.active[i].IsVisible === '0') {
        stepBoxSumm = state.sendData.CALC_SUMM * 0.1;
        if ((stepBoxSumm + state.sendData.CALC_SUMM) === parseInt(state.deal.active[i].Amount, 10)) {
          out.push(state.deal.active[i])
        }
      }
    }
    return out;
  },

  getFirstSecondName: (state) => {
    return (state.sendData.FIRST_NAME.charAt(0) + state.sendData.FIRST_NAME.slice(1).toLowerCase()
      + ' '
      + state.sendData.SECOND_NAME.charAt(0) + state.sendData.SECOND_NAME.slice(1).toLowerCase())
      .trim()
  },

  //

  getTargetFieldValue: (state) => (target) => state.sendData['request'][target],
  isAdressValid: (state) => (target) => {
    if (
      state.sendData[target] == '' &&
      state.sendData[target + '_DADATA'].value == ''
    ) {
      return 'Обязательное поле, Вы должны выбрать адрес из выпадающего списка';
    }

    if (state.sendData[target + '_DADATA'].value != state.sendData[target]) {
      return 'Вы должны выбрать адрес из выпадающего списка';
    }

    try {
      if (state.sendData[target + '_DADATA'].data.house == null) {
        return 'Адрес не полный, укажите город, улицу и номер дома';
      }
    } catch (e) {
      return 'Адрес не корректный';
    }
    return '';
  },
  getMobileAdressSuggestion: (state) => (target) => {
    return (
      state.mobileSuggestion[target].area +
      ' ' +
      state.mobileSuggestion[target].city +
      ' ' +
      state.mobileSuggestion[target].street +
      ' ' +
      state.mobileSuggestion[target].house +
      ' ' +
      state.mobileSuggestion[target].deportament
    );
  },
  fieldForCheckDublicateLead: (state) => {
    return {
      SECOND_NAME: state.sendData.SECOND_NAME,
      LAST_NAME: state.sendData.LAST_NAME,
      FIRST_NAME: state.sendData.FIRST_NAME,
      PHONE: state.sendData.PHONE
    };
  },
  fieldForCheckPassportDublicateLead: (state) => {
    return {
      REGISTRATION_ADDRESS: state.sendData.REGISTRATION_ADDRESS,
      FIRST_NAME: state.sendData.FIRST_NAME,
      LAST_NAME: state.sendData.LAST_NAME,
      SECOND_NAME: state.sendData.SECOND_NAME,
      PASSPORT_SERIES: state.sendData.PASSPORT_SERIES,
      PASSPORT_NUMBER: state.sendData.PASSPORT_NUMBER,
      BIRTHDAY: state.sendData.BIRTHDAY
    };
  },


  getSelectedDeal: (state) => {
    const objDeal = state.deal.active.filter(x => parseInt(x.Amount, 10) === state.sendData.CALC_SUMM
      && parseInt(x.loanPeriod, 10) === state.sendData.CALC_WEEK)
    return objDeal[objDeal.length - 1];
  }
};

const actions = {
  init: async ({ getters, dispatch, commit }) => {
    try {
      const userId = localStorage.getItem('UserID');
      if (!getters.hasSession) {
        const session = await tracing.startSession(userId);
        dispatch('tracingSessionStart', session);
      }
      const deals = await getDeal();
      commit('setDealPskAll', deals.result);

      dispatch('setFilteredDealActive');

      const refs = await dispatch('getRefs');
      dispatch('saveRefs', refs);

      const userInfo = await getClientInfo(userId, localStorage.getItem('SessionKey'));
      dispatch('saveUserData', userInfo.result);

    } catch (e) {
      console.log(e);
      commit('setErrorStatus', true);
      commit('setErrorMessage', e);
    }
  },

  getRefs: async () => {
    // дергаем АПИ
    return await new Promise((resolve, _) => resolve(TestRefs()));
  },

  // todo минимальное значение
  setDefaultCalcParams: ({ getters, commit }) => {
    commit('saveSendData', {
      target: 'CALC_SUMM',
      value: getters.userCanPdl ? 7000 : 30000
    });
    commit('saveSendData', {
      target: 'CALC_WEEK',
      value: getters.userCanPdl ? 14 : 50
    });
  },
  saveRefs: ({ commit }, refs) => {
    for (const { fieldName, fieldValues } of refs) {
      commit('setRef', {
        target: fieldName,
        value: fieldValues
      });
    }
  },
  saveUserData: ({ dispatch }, userInfo) => {
    for (let prop in userInfo) {
      if (state.sendData.hasOwnProperty(prop)) {
        if (prop.endsWith('_DADATA')) {

          userInfo[prop] = typeof userInfo[prop] === 'string' ? JSON.parse(userInfo[prop]) : userInfo[prop];
        }
        dispatch('saveSendData', {
          target: prop,
          value: userInfo[prop]
        });
      }
      if (prop === 'ClientID') {
        localStorage.setItem('cid', userInfo[prop])
      }
    }
  },
  setFilteredDealActive: ({ state, getters, dispatch, commit }) => {
    let filteredDeals = state.deal.all
    if (!getters.userCanPdl) {
      filteredDeals = filteredDeals.filter(x => ['S', 'K'].includes(x.ProductType));
    }
    if (!getters.userCanBase) {
      filteredDeals = filteredDeals.filter(x => ['P', 'O', 'T', 'Y'].includes(x.ProductType));
    }
    commit('setDealPskActive', filteredDeals);
    dispatch('setCalcRefs');
  },
  setCalcRefs: ({ commit, getters }) => {
    commit('setRef', {
      target: 'CALC_SUMM',
      value: getters.getActiveDistinctAmounts
    });
    commit('setRef', {
      target: 'CALC_WEEK',
      value: getters.getActiveDistinctPeriods
    });
  },

  changeCurrentScreen: ({ state, commit }, name) => {
    if (![...state.system.availableScreens, ...state.system.systemScreens].includes(name)) {
      return;
    }
    commit('setCurrentScreen', name);
  },

  changeNextScreen: ({ state, commit }, name) => {
    if (![...state.system.availableScreens, ...state.system.systemScreens, ''].includes(name)) {
      return;
    }
    commit('setNextScreen', name);
  },

  tracingSessionStart: ({ commit }, { session_id, token }) => {
    commit('setSessionId', session_id);
    commit('setToken', token);
  },

  startGeo: async ({ state, commit, dispatch }) => {
    commit('setGeoLoading', true);
    if (state.system.geoTimer) {
      clearTimeout(state.system.geoTimer);
    }
    const timerId = setTimeout(async () => {
      try {
        const result = await external.universalRequest({
          externalType: 'zita',
          action: 'GetAddressTerritory',
          Bindi: localStorage.getItem('SessionKey'),
          Address: state.sendData.RESIDENTIAL_ADDRESS,
          PartnerID: "2",
          RESIDENTIAL_ADDRESS_DADATA: state.sendData.RESIDENTIAL_ADDRESS_DADATA
        });
        commit('setGeoData', result);
        dispatch('changeNextScreen', 'waiting');
      } catch (e) {
        commit('setGeoData', undefined);
        commit('setGeoStatus', false);
        dispatch('changeNextScreen', 'rejection');
      }
      finally {
        commit('setGeoLast', state.sendData.RESIDENTIAL_ADDRESS);
        commit('setGeoLoading', false);

      }
    }, 3000);
    commit('setGeoTimer', timerId)
  },

  sendScreenInfo: async ({ state, commit, getters }) => {
    await tracing.traceInput({
      action: 165,
      refs: 'CALC_SUMM',
      value: state.sendData.CALC_SUMM
    });
    await tracing.traceInput({
      action: 165,
      refs: 'CALC_WEEK',
      value: state.sendData.CALC_WEEK
    });
    await tracing.sendTrace(getters.getSession.session_id, getters.getSession.token);
    const userId = localStorage.getItem('UserID');
    const sessionKey = localStorage.getItem('SessionKey');
    const params = {
      ClientId: parseInt(localStorage.getItem('cid'), 10),
      UserID: userId,
      SessionKey: sessionKey,
      session_id: getters.getSession.session_id,
      token: getters.getSession.token,
      newScr: state.system.nextScreen,
      oldScr: state.system.currentScreen
    }
    if (state.system.currentScreen === 'signingLoan') {
      params.SendToken = true;
      params.TraceIdWebix = state.system.TraceId;
    }
    const { result } = await loanDraftChangeScreen(params);
    // to full fail
    if (!!result?.TraceId) {
      commit('saveSystemData', {
        target: 'loanDemandId',
        value: result.loan.DemandId
      });
      commit('saveSystemData', {
        target: 'smsGuid',
        value: result.sms.Data
      });
      commit('saveSystemData', {
        target: 'TraceId',
        value: result.TraceId
      });
    }
  },


  pdlConfirmCodeCheck: async ({ state, getters }) => {
    const params = {
      cid: parseInt(localStorage.getItem('cid'), 10),
      smsGuid: state.system.smsGuid,
      session_id: getters.getSession.session_id,
      token: getters.getSession.token,
      code: state.sendData.pdlSmsConfCode,
      traceId: state.system.TraceId
    }
    try {
      const res = await loanDraftPdlConfirmCodeCheck(params);
      return (res?.result?.Data);
    } catch (e) {
      console.log(e);
      return null;
    }
  },

  validatorArrField: ({ state, dispatch }, { arrChekedField, showError = true }) => {
    let result = true;
    arrChekedField.forEach((item) => {
      if (state.workData[item].code !== 0) {
        if (showError) {
          dispatch('errorShow', { target: item, value: true });
        }
        result = false;
      }
    });

    return result;
  },

  validateForm: async ({ state, getters, dispatch }) => {
    let fields = [
      'PHONE',
      'EMAIL',
      'RESIDENTIAL_ADDRESS',
      'SECOND_NAME',
      'LAST_NAME',
      'BIRTHDAY',
      'FIRST_NAME',
      'GENDER',
      'PASSPORT_SERIES',
      'PASSPORT_NUMBER',
      'PASSPORT_DEP_CODE',
      'PASSPORT_DATE',
      'BIRTHPLACE',
      'REGISTRATION_ADDRESS',
      'RESIDENTIAL_ADDRESS',
      'PASSPORT_DEP',
      'SNILS',
      'WORK_JOB',
      'EXPER_RADIO',
      'CONST_INCOME',
      'EDUCATION',
      'MARITAL_STATUS',
      'MINOR_CHILDREN',
      'SOURCE_OF_INCOME',
      'PURPOSE_OF_THE_LOAN',
      'FINANCIAL_POSITION',
      'MY_OWN_BENEFIT',
      'ACTIONS_CONTROL',
      'NOT_RELATED'
    ];
    if (!getters.isPdl) {
      fields.push('MEETING_DATE');
      fields.push('MEETING_HOUR');
    }
    let result = await dispatch('validatorArrField', { arrChekedField: fields });

    const checkResidentalAddress = getters.isAdressValid('RESIDENTIAL_ADDRESS');
    if (checkResidentalAddress.length > 0) {
      dispatch('fieldError', {
        target: 'RESIDENTIAL_ADDRESS',
        value: checkResidentalAddress
      });
      result = false;
    }
    const checkRegistrationAdress = getters.isAdressValid('REGISTRATION_ADDRESS');
    if (checkRegistrationAdress.length > 0) {
      dispatch('fieldError', {
        target: 'REGISTRATION_ADDRESS',
        value: checkRegistrationAdress
      });
      result = false;
    }
    if (state.sendData['CALC_SUMM'] > 10000) {
      let newConstIncotme = parseInt(state.sendData['CONST_INCOME'], 10) * 3;
      newConstIncotme = newConstIncotme <= 150000 ? newConstIncotme : 150000;
      dispatch('saveSendData', {
        target: 'CONST_INCOME',
        value: newConstIncotme
      });
    }
    dispatch('clickSaveData', fields);

    return result;
  },

  validateCard: async ({ state }) => {
    return (state.sendData.PAYMENT_TYPE === 'TinkoffE2C' && state.sendData.tinkoffE2C_cardSelected.cardId !==
      undefined) ||
      (state.sendData.PAYMENT_TYPE === 'TinkoffA2C' && state.sendData.tinkoffA2C_bankSelected.memberName !==
        undefined);
  },

  clickSaveData: ({ state }, fields) => {
    let valuesFields = {};
    fields.forEach((key) => {
      valuesFields[key] = state.sendData[key];
    });
    tracing.traceInput({
      action: 170,
      value: valuesFields
    })
  },

  saveBase: async ({ getters }) => {
    const session_id = getters.getSession.session_id;
    const token = getters.getSession.token;
    try {
      const { result } = await loanDraftSaveBaseProduct(session_id, token);
      return result.longId;
    } catch (e) {
      console.log(e);
    }

  },

  setLongId: ({ commit }, longId) => {
    commit('setLongId', longId)
  },

  //

  setMobileAdressSuggestion: ({ commit }, requestFields) => {
    commit('saveMobileAdressSuggestion', requestFields);
  },
  setRequestField: ({ dispatch }, requestFields) => {
    try {
      // console.log(requestFields)
      Object.keys(requestFields).forEach(function (key) {
        if (key in state.sendData) {
          if (
            requestFields[key] !== state.sendData[key] &&
            requestFields[key] != ''
          ) {
            dispatch('saveSendData', {
              target: key,
              value: requestFields[key]
            });
          }
        }
      });
    } catch (e) {
      // console.log(e);
    }
  },
  setParamsDataUrl: ({ dispatch }, requestFields) => {
    try {
      Object.keys(requestFields).forEach((key) => {
        if (key in state.sendData) {
          console.log(state.sendData);
          dispatch('saveSendData', {
            target: key,
            value: requestFields[key]
          });
        }
      });
    } catch (err) {
      console.log('Set getparams vuex data', err);
    }
  },

  saveSendData: async ({ commit, dispatch, getters }, workArr) => {
    // console.log(workArr);
    //если пришли кривые данные  не возбуждать state и еже с ним
    if (workArr.value === undefined) {
      return false;
    }
    // workArr.value = transformValueBeforeSave(workArr);
    if (typeof workArr.value === 'string') {
      workArr.value = workArr.value.trim()
    }
    
    if (state.sendData[workArr.target] === workArr.value) {
      return false;
    }
    //блок запуска колдунства над введенными буквами пользователя, принимает решение на сколько криво пользователь
    // воспользовался пальцами
    let statusArr = {};
    if (typeof FormFieldCheck[workArr.target] == 'function') {
      statusArr = FormFieldCheck[workArr.target](workArr.value);
    } else {
      statusArr = FormFieldCheck.isEmpty(workArr.value);
    }
    workArr['status'] = statusArr;

    //todo переписать и найти решение, проблема как указать дерективу на инпут с уникальным шаблоном маски, если не каждому инпуту нужна такая директива.
    if (typeof FormFieldCheck[workArr.target + 'maskKostil'] == 'function') {
      workArr.value = FormFieldCheck[workArr.target + 'maskKostil'](
        workArr.value
      );
    }

    //сохраняем значение и статус введеного
    await tracing.traceInput({
      action: 165,
      refs: workArr.target,
      value: workArr.value
    });
    commit('saveSendData', workArr);
    commit('saveWorkData', workArr);
    if (workArr.target === 'CALC_SUMM' || workArr.target === 'CALC_WEEK') {
      await tracing.traceInput({
        action: 165,
        refs: 'pdlGetSelectedDeal',
        value: getters.getSelectedDeal
      });
    }

    //особые поля особые костыли
    if (workArr.target === 'IS_ADDRESS_COINCIDES') {
      dispatch('isAdressConsides');
    }

    if (state.sendData.IS_ADDRESS_COINCIDES === 'Y') {
      if (workArr.target === 'REGISTRATION_ADDRESS') {
        dispatch('cloneAdress', {
          from: 'REGISTRATION_ADDRESS',
          to: 'RESIDENTIAL_ADDRESS'
        });
      }
      if (workArr.target === 'RESIDENTIAL_ADDRESS') {
        dispatch('cloneAdress', {
          from: 'RESIDENTIAL_ADDRESS',
          to: 'REGISTRATION_ADDRESS'
        });
      }
    }

    if (workArr.target === 'CALC_SUMM') {
      dispatch('checkPeriodForCurrentCalcSumm');
    }
    if (workArr.target === 'CALC_WEEK') {
      dispatch('checkSummForCurrentPeriod');
    }

  },
  cloneAdress: ({ commit }, cloneObject) => {
    commit('saveSendData', {
      target: cloneObject.to,
      value: state.sendData[cloneObject.from]
    });
    commit('saveSendData', {
      target: cloneObject.to + '_DADATA',
      value: state.sendData[cloneObject.from + '_DADATA']
    });
  },
  resetToDefault: ({ commit }) => {
    return new Promise((resolve) => {
      commit('resetState');
      resolve();
    });
  },

  isAdressConsides: ({ commit }) => {
    let workArr = {
      target: 'REGISTRATION_ADDRESS_DADATA',
      value: ''
    };
    let statusArr = {};
    if (state.sendData.IS_ADDRESS_COINCIDES === 'Y') {
      workArr.value = state.sendData.RESIDENTIAL_ADDRESS_DADATA;
      if (typeof FormFieldCheck[workArr.target] == 'function') {
        statusArr = FormFieldCheck[workArr.target](workArr.value);
      } else {
        statusArr = FormFieldCheck.isEmpty(workArr.value);
      }
      workArr['status'] = statusArr;
      commit('saveSendData', workArr);
      commit('saveWorkData', workArr);

      workArr.target = 'REGISTRATION_ADDRESS';
      workArr.value = state.sendData.RESIDENTIAL_ADDRESS;
      if (typeof FormFieldCheck[workArr.target] == 'function') {
        statusArr = FormFieldCheck[workArr.target](workArr.value);
      } else {
        statusArr = FormFieldCheck.isEmpty(workArr.value);
      }
      workArr['status'] = statusArr;
      commit('saveSendData', workArr);
      commit('saveWorkData', workArr);
    }
  },
  systemUpdate: ({ commit }, valueObj) => {
    if (valueObj.target == undefined && valueObj.value == undefined)
      return false;
    commit('saveSystemData', valueObj);
  },
  errorShow: ({ commit }, valueObj) => {
    if (valueObj.target == undefined && valueObj.value == undefined)
      return false;
    commit('saveErrorShow', valueObj);
  },
  showLoading: ({ commit }) => {
    commit('saveSystemData', {
      target: 'loading',
      value: true
    });
  },
  hideLoading: ({ commit }) => {
    commit('saveSystemData', {
      target: 'loading',
      value: false
    });
  },
  formRouting: ({ commit }, target) => {
    commit('saveSystemData', {
      target: 'currentFormScreen',
      value: target
    });
  },
  fieldError: ({ commit }, valueObj) => {
    if (valueObj.target == undefined && valueObj.value == undefined)
      return false;
    commit('saveErrorShow', {
      target: valueObj.target,
      value: true
    });
    // commit('kostil', 1);
    commit('saveErrorMsg', valueObj);
  },
  // eslint-disable-next-line no-unused-vars
  getResultLoan: ({ commit, dispatch }, iteration) => {
    console.log('Run getResultLoan');
    return new Promise((resolve, reject) => {
      if (state.system.loanid === 0) {
        console.log('loanId - false');
        reject('loanid === 0');
        return false;
      }
      console.log('Promise getResultLoan');
      myApi
        .SendRequest({
          action: 'CheckDecisionByID',
          typeRequest: 'loan',
          loandId: state.system.loanid
        })
        .then((res) => {
          console.log('Rezult getResultLoan');
          if (res.onItsWay === 'true') {
            commit('saveSystemData', {
              target: 'DealProductType',
              value: res.DealProductType === 'true' ? true : false
            });
            commit('saveSystemData', {
              target: 'isPDL',
              value: res.isPDL === 'true' ? true : false
            });
            if (res.pdlLimit != undefined) {
              commit('saveSystemData', {
                target: 'pdlLimit',
                value: res.pdlLimit
              });
            }
            if (res.loanLimit != undefined) {
              commit('saveSystemData', {
                target: 'loanLimit',
                value: res.loanLimit
              });
            }
            if (res.demandid != undefined) {
              commit('saveSystemData', {
                target: 'loanDemandId',
                value: res.demandid
              });
            }

            commit('saveSystemData', {
              target: 'onItsWay',
              value: res.onItsWay === 'true' ? true : false
            });
            resolve(res);
          } else {
            switch (iteration.i) {
              case 1:
                iteration.time = 20000;
                break;
              case 2:
                iteration.time = 5000;
                break;
            }
            iteration.i += 1;
            setTimeout(() => {
              dispatch('getResultLoan', iteration)
                .then((res) => resolve(res))
                .catch((res) => reject(res));
            }, iteration.time);
          }
        })
        .catch((res) => {
          reject(res);
        });
    });
  },

  dealOnlyPdl({ commit, getters, dispatch }) {
    let newDealArr = [];
    for (let i = 0; i < state.deal.all.length; i += 1) {
      if (state.deal.all[i].vwIsPdl === '1') {
        newDealArr.push(state.deal.all[i]);
      }
    }
    let lastSumm = state.sendData.CALC_SUMM;
    commit('setDealPskActive', newDealArr);
    commit('saveDealRef', {
      target: 'CALC_SUMM',
      value: getters.getActiveDistinctAmount
    });
    commit('saveDealRef', {
      target: 'CALC_WEEK',
      value: getters.getActiveDistinctPeriod
    });

    if (lastSumm > getters.getActiveMaxDeal) {
      dispatch('saveSendData', {
        target: 'CALC_SUMM',
        value: getters.getActiveMaxDeal
      });
    }
  },
  checkPeriodForCurrentCalcSumm({ commit, getters }) {
    let posibleDeal = [];
    for (let i = 0; i < state.deal.active.length; i += 1) {
      if (
        parseInt(state.deal.active[i].Amount, 10) === state.sendData.CALC_SUMM
      ) {
        posibleDeal.push(state.deal.active[i]);
      }
    }
    posibleDeal.sort((a, b) => {
      return parseInt(a.loanPeriod, 10) - parseInt(b.loanPeriod, 10);
    });
    //проверяем выбранный ранее пользователем период совпадает ли с новой суммой
    let chekInclude = false;
    // if (getters.getActiveDistinctPeriods[getters.getActiveCalcWeekIndex] !== undefined) {
    //   console.log('TEST', getters.getActiveDistinctPeriods[getters.getActiveCalcWeekIndex] !== undefined);
    //   for (let i = 0; i < posibleDeal.length; i += 1) {
    //     if (getters.getActiveDistinctPeriods[getters.getActiveCalcWeekIndex].value == posibleDeal[i].loanPeriod) {
    //       chekInclude = true;
    //     }
    //   }
    // }
      for (let i = 0; i < posibleDeal.length; i += 1) {
        if (state.sendData.CALC_WEEK == posibleDeal[i].loanPeriod) {
          chekInclude = true;
        }
      }

      if (chekInclude) {
        return false;
      }

    if (!chekInclude && posibleDeal.length >= 1) {
      //повезло есть доступный период
      commit('saveSendData', {
        target: 'CALC_WEEK',
        value: parseInt(posibleDeal[posibleDeal.length - 1].loanPeriod, 10)
      });
    }
  },
  checkSummForCurrentPeriod({ commit }) {
    let minAmount = null;
    let maxAmount = null;
    let stepSumm;
    //state.sendData.CALC_SUMM
    //получаем мин макс суммы для нового периода
    //todo для каждого периода, можно знать мин макс заранее
    for (let i = 0; i < state.deal.active.length; i += 1) {
      stepSumm = parseInt(state.deal.active[i].Amount, 10);

      if (
        parseInt(state.deal.active[i].loanPeriod, 10) ===
        state.sendData.CALC_WEEK
      ) {
        if (minAmount === null) {
          minAmount = stepSumm;
        } else if (minAmount > stepSumm) {
          minAmount = stepSumm;
        }
        if (maxAmount === null) {
          maxAmount = stepSumm;
        } else if (maxAmount < stepSumm) {
          maxAmount = stepSumm;
        }
      }
    }
    //если текущая сумма не попадает в новый период ставим новую возможную

    if (
      !(
        state.sendData.CALC_SUMM > minAmount &&
        state.sendData.CALC_SUMM < maxAmount
      )
    ) {
      // commit('pdlSetSelectedAmount',minAmount);
      commit('saveSendData', {
        target: 'CALC_SUMM',
        value: minAmount
      });
    }
  }
};

//todo убрать лишние сохронялки в хранилише, проверять на совпадение новго и старого значения.
const mutations = {
  setCurrentScreen: (state, value) => (state.system.currentScreen = value),
  setScreenMessage: (state, value) => (state.system.scrMsg = value),
  setNextScreen: (state, value) => (state.system.nextScreen = value),

  setErrorStatus: (state, value) => (state.system.isError = value),
  setErrorMessage: (state, value) => (state.system.errorMessage = value),

  /**
   * сохранение значение поля формы в ебический объект формы
   * @param state
   * @param newVal object свойство target(название поля формы) value(значение поле формы) очень обязательное
   * @returns {*}
   */
  saveSendData: (state, newVal) => {
    state.sendData[newVal.target] = newVal.value;
  },
  setDealPskActive: (state, value) => (state.deal.active = value),
  setDealPskAll: (state, value) => (state.deal.all = value),
  setRef: (state, { target, value }) => (state.refs[target] = value),

  setSessionId: (state, value) => state.tracing.session_id = value,
  setToken: (state, value) => state.tracing.token = value,
  setGeoTimer: (state, value) => state.system.geoTimer = value,
  setGeoLast: (state, value) => state.geo.last = value,
  setGeoData: (state, value) => state.geo.data = value,
  setGeoStatus: (state, value) => state.geo.status = value,

  setSystemLoading: (state, value) => state.system.loading = value,
  setGeoLoading: (state, value) => state.geo.isLoading = value,

  setLongId: (state, value) => state.system.longId = value,


  //

  setValuesToCheckbox: (state, newVal) => {
    state.sendData.THIRD_PARTIES =
      state.sendData.ACCEPTANCE_OF_THE_TERMS =
      state.sendData.AGREEMENT_WITH_THE_RULES =
      // state.sendData.AGREEMENT_LONGATSIA_RULES =
      // state.sendData.AGREEMENT_DATA_TRANSMISSION =
      // state.sendData.AGREEMENT_KI_REQUEST =
      state.sendData.GENERAL_TERMS_OF_MICROLOAN =
      state.sendData.RULES_FOR_MICROLOAN =
      // state.sendData.APPLICATION_MICROLOAN =
      state.sendData.AGREEMENT_IU =
      state.sendData.AGREEMENT_PDN =
      state.sendData.REQURENT_PAY =
      // state.sendData.LOAN_WITH_INSURANCE =
      newVal;

    state.workData.THIRD_PARTIES =
      state.workData.ACCEPTANCE_OF_THE_TERMS =
      state.workData.AGREEMENT_WITH_THE_RULES =
      // state.workData.AGREEMENT_LONGATSIA_RULES =
      // state.workData.AGREEMENT_DATA_TRANSMISSION =
      // state.workData.AGREEMENT_KI_REQUEST =
      state.workData.GENERAL_TERMS_OF_MICROLOAN =
      state.workData.RULES_FOR_MICROLOAN =
      // state.workData.APPLICATION_MICROLOAN =
      state.workData.AGREEMENT_IU =
      state.workData.AGREEMENT_PDN =
      state.workData.REQURENT_PAY =
      // state.workData.LOAN_WITH_INSURANCE =
      newVal
        ? { code: 0, msg: ' ', ShowError: false }
        : { code: 1, msg: 'Обязательный пункт', ShowError: true };
  },

  saveWorkData: (state, newVal) => {
    state.workData[newVal.target].code = newVal.status.code;
    state.workData[newVal.target].msg = newVal.status.msg;
  },
  kostil: (state, newVal) => (state.workData.PHONE.code = newVal),
  saveErrorShow: (state, valueObj) =>
    (state.workData[valueObj.target].ShowError = valueObj.value),
  saveErrorMsg: (state, valueObj) =>
    (state.workData[valueObj.target].msg = valueObj.value),
  saveSystemData: (state, valueObj) => (state.system[valueObj.target] = valueObj.value),
  resetState: (state) => Object.assign(state, getDefaultState()),

  saveScrMsg: (state, str) => (state.system.scrMsg = str),
  selectAdressForm: (state) => {
    state.system.isMobileAdressForm = window.outerWidth < 760;
  },
  saveMobileAdressSuggestion: (state, valueObj) => {
    state.mobileSuggestion[valueObj.target][valueObj.adresPart] =
      valueObj.value;
  },
  showThanks: (state) => (state.system.currentFormScreen = 'scrEnd'),
  setGuid: (state, newGuid) => (state.system.guid = newGuid),
  setPartnerId: (state, newPartnerId) =>
    (state.system.Partner_Id = newPartnerId)
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
