const state = {
  PhoneNumber: '',
  DlCode:'',
  password: '',
  SessionKey: localStorage.getItem('SessionKey') || '',
  UserID:localStorage.getItem('UserID') || '',
};

const getters = {
  isAuthenticated: state => {

      return typeof state.UserID === 'string' && typeof state.SessionKey === 'string'
          ? state.UserID.length > 0 && state.SessionKey.length > 0
          : false;


  },
};

const actions = {
  setDlCode:({commit}, DlCode) => {
    if (DlCode != undefined && DlCode > 500000 && !isNaN(DlCode)) {
      commit('setDlCode', DlCode);
      localStorage.setItem('setDlCode', true);
    }
  },
};

const mutations = {
  setLogin: (state, value) => state.PhoneNumber = value,
  setDlCode: (state, value) => state.DlCode = value,
  setUserID: (state, value) => {
    localStorage.setItem('UserID', value);
    state.UserID = value
  },
  setSessionKey: (state, value) => {
    localStorage.setItem('SessionKey', value);
    localStorage.setItem('SessionKeyCreate', new Date().getTime());
    state.SessionKey = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
