import axios from 'axios';

const msUrl = process.env.VUE_APP_URL_API_MS;

export const getDeal = async function () {
  const { data } = await axios.post(`${msUrl}/getDeal.php`);
  return data;
}

export const loanDraftChangeScreen = async function (params) {
  const { data } = await axios.post(`${msUrl}/loanDraftCdScreen.php`, { ...params });
  return data;
}

export const loanDraftPdlConfirmCodeCheck = async function (params) {
  const { data } = await axios.post(`${msUrl}/loanDraftPdlConfirmCodeCheck.php`, { ...params });
  return data;
}

export const loanDraftSaveBaseProduct = async function (session_id, token) {
  const { data } = await axios.post(`${msUrl}/loanDraftSaveBaseProduct.php`, { session_id, token });
  return data;
}

export const getClientInfo = async function (UserID, SessionKey) {
  const { data } = await axios.post(`${msUrl}/getClientInfo.php`, { UserID, SessionKey });
  return data;
}