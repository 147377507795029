function isErrorInDadataValue(dadataObj){
  if (this.query != this.selected.value) {
    this.ErrorSelected = 'Адрес должен быть выбран из списка';
    return false;
  } else {
    this.$emit('selected', this.selected);
    this.ErrorSelected = '';
  }
}

const state = {
  RESIDENTIAL_ADDRESS:{},
  isErrorInRESIDENTIAL_ADDRESS:true
};

const getters = {
  getDadataResidental: state => state.RESIDENTIAL_ADDRESS,
  isErrorInRESIDENTIAL_ADDRESS: state => state.isErrorInRESIDENTIAL_ADDRESS
};

const actions = {
  setResidentalValue:({commit}, value) => {

    commit('changeState',!state.open);
  }
};

const mutations = {
  changeState: (state, value) => state.open = value,
};

export default {
  state,
  getters,
  actions,
  mutations,
}
